import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { IPrice } from 'src/app/shared/models/price/price.model';

export interface IOrderItemPriceUpdateData {
  orderItemId: number;
  basePriceValue: number;
  reservationId: number;
}

export interface IAppliedDiscountTemplate {
  title: string;
  percentValue: number;
  id: number;
  appliedAt: Date;
  discountValue: IPrice[];
  previousPurchase: boolean;
}

export interface IDiscountsAppliedChangeCallableData {
  reservationId: number;
  discountTemplateId: number;
  percentValue: number;
}

@Injectable({
  providedIn: 'root'
})
export class OrderItemsService {

  constructor(
    private dbService: DbService,
  ) { }

  public updatePrice(data: IOrderItemPriceUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrderItemsChangePrice, data });
    return this.dbService.handleObs(obs);
  }

  public updateDiscountValue(data: IDiscountsAppliedChangeCallableData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesDiscountsAppliedChange, data });
    return this.dbService.handleObs(obs);
  }
}
