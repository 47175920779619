

@if (columnItem) {
  <div class="reservation-table-column-item">

    <div class="flex align-items-center column-gap-2">
      <p-checkbox inputId="_{{columnItem.itemType}}" [binary]="true" [ngModel]="selected" (onChange)="onCheckboxChange($event, columnItem)"></p-checkbox>


      <div class="flex flex-column">
        <div class="flex flex-row gap-1">
          <span class="font-bold">
            {{ columnItem.title | translate }}
          </span>
        </div>
        <div class="details-block-wrap text-xs line-height-1">
          @if (showGroup) {
            <div class="detail-block">
              <span class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 25ch;">
                {{ (groupName + '.' + columnItem.groupType) | translate }}
              </span>
            </div>
          }
            @if (columnItem.description | translate) {
              <div class="detail-block">
                <span class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 50ch;">
                  {{ columnItem.description | translate }}
                </span>
              </div>
            }
          </div>
      </div>

    </div>

    <ng-content select="[slot=drag]">

    </ng-content>

  </div>
}
