import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class IcoService {

  constructor() { }

  public icoValidator = (control: AbstractControl) => {
    const icoStr = control.value;
    if (icoStr) {
      let controlCount = 0;
      for (let i = 0; i < icoStr.length; i++) {
        if (i === icoStr.length - 1) {
          break;
        }
        const char = icoStr[i];
        if (!this.isCharDigit(char)) {
          return { icoInvalid: true };
        }
        controlCount += icoStr[i] * (8 - i);
      }

      controlCount = controlCount % 11;
      let c;
      if (controlCount === 0) {
        c = 1;
      } else if (controlCount === 1) {
        c = 0;
      } else {
        c = 11 - controlCount;
      }

      if (icoStr[icoStr.length - 1] === `${c}`) {
        return null;
      } else {
        return {icoInvalid: true};
      }
    }
    // If icoStr is null or empty, required validator error will be raised
    return null;
  }

  public isCharDigit = (c: string) => {
    return /^\d+$/.test(c);
  }
}
