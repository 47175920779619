import { IChangeData } from "../_other/change-data.model";

export enum OrganizationCustomDomainType {
  CUSTOMER_SITE = 'CUSTOMER_SITE',
  ADMIN_SITE = 'ADMIN_SITE',
}

export enum OrganizationCustomDomainStatus {
  VERIFIED = 'VERIFIED', // dns records are set correctly
  PENDING = 'PENDING', // 48 hours after creation, dns records not yet set correctly
  ERROR = 'ERROR', // 48 hours +, dns records not set correctly
}

export enum OrganizationCustomDomainHistoryEvent {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  STATUS_CHANGED = 'STATUS_CHANGED',
};

export interface IOrganizationCustomDomainHistoryEvent extends IChangeData {
  event: OrganizationCustomDomainHistoryEvent; // what happened
  datetime: string;
  data?: {
    prevStatus?: OrganizationCustomDomainStatus;
    status?: OrganizationCustomDomainStatus;
    prevDomain?: string;
    domain?: string;
  };
}

export interface IOrganizationCustomDomain {
  uuid: string;
  type: OrganizationCustomDomainType;
  status: OrganizationCustomDomainStatus;
  domain: string;
  lastCheckedAt: string | null;
  createdAt: string;
  dnsRecordsToSet: IOrganizationCustomDomainRecord[]; // static - set on creation
  dnsRecordsToRemove: IOrganizationCustomDomainRecord[]; // dynamic - can change in each dns check

  historyEvents: IOrganizationCustomDomainHistoryEvent[];
}
export interface IOrganizationCustomDomainRecord {
  type: 'A' | 'TXT';
  host: string;
  value: string;
}

export interface IOrgCustomDomainsVerifiedFE {
  customerDomain: string | null;
}