


<ng-container *ngIf="(state.fetchingUserData$ | async); else notFetching">
  <div class="flex justify-content-center align-items-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-container>

<ng-template #notFetching>
  <ng-container *ngIf="(state.customerCard$ | async)?.userData as userData">
    
    <div style="flex: 1 1 auto;">

      <app-custom-data-inputs [emailFC]="emailFC" [orgUserNameFG]="nameFG" [customDataFG]="customDataFG" [userData]="userData"></app-custom-data-inputs>

    </div>
    
    

    <button
      *ngIf="(customDataFG.dirty && !customDataFG.disabled) || (nameFG.dirty && !nameFG.disabled) || (emailFC?.dirty && !emailFC?.disabled)"
      (click)="onSaveCustomData()"
      [loading]="!!(state.updatingUserData$ | async)"
      pButton
      label="{{ 'admin.customer-card-customer.save-btn.label' | translate }}">
    </button>

  </ng-container>
</ng-template>