import { TransferType } from "./transfer-types.enum";


export enum TransferPaymentMethod {
  PAYMENT_GATE = 'PAYMENT_GATE',
  BANK_TRANSFER = 'BANK_TRANSFER',
  INTERNAL_CUSTOMER_CREDIT = 'INTERNAL_CUSTOMER_CREDIT',
  CASH = 'CASH',
};

export const TransferPaymentMethodName = new Map<TransferPaymentMethod, string>([
  [TransferPaymentMethod.PAYMENT_GATE , 'Platební brána'],
  [TransferPaymentMethod.BANK_TRANSFER , 'Bankovní převod'],
  [TransferPaymentMethod.INTERNAL_CUSTOMER_CREDIT , 'Přičtení kreditu'],
  [TransferPaymentMethod.CASH , 'Hotovost'],
]);

export const TransferPaymentMethodTypeName = new Map<TransferPaymentMethod, Map<TransferType, string>>([
  [TransferPaymentMethod.PAYMENT_GATE , new Map([
    [TransferType.CREDIT, 'Platební brána'],
    [TransferType.INCOME, 'Platební brána']
  ])],
  [TransferPaymentMethod.BANK_TRANSFER , new Map([
    [TransferType.CREDIT, 'Bankovní převod'],
    [TransferType.INCOME, 'Bankovní převod']
  ])],
  [TransferPaymentMethod.INTERNAL_CUSTOMER_CREDIT , new Map([
    [TransferType.CREDIT, 'Převod na kredit'],
    [TransferType.INCOME, 'Platba z kreditu'],
    [TransferType.REFUND, 'Výplata kreditu']
  ])],
  [TransferPaymentMethod.CASH , new Map([
    [TransferType.CREDIT, 'Hotovost'],
    [TransferType.INCOME, 'Hotovost']
  ])]
]);
