<app-modal-header
  (close)="close()">
  <span slot="title">
    {{'admin.form-settings-modal.title' | translate }}
  </span>
</app-modal-header>


<div [formGroup]="form" class="modal-body ">
  <div class="grid align-items-center">

    <div class="col-9">
      <span class="p-float-label field">
        <input id="newTitle" formControlName="newTitle" type="text" pInputText class="w-full">
        <label for="newTitle">{{'admin.form-settings-modal.new.title' | translate }}</label>
        <small *ngIf="form.controls.newTitle.hasError('title')" class="pl-2 -mt-2 p-error">{{'admin.form-field-settings-modal.alreadyexists' | translate }}</small>
      </span>
    </div>
    <button
      pButton
      (click)="onCreate()"
      label="{{'admin.form-settings-modal.new.create' | translate }}"
      icon="pi pi-plus">
    </button>
  
    <ng-container *ngIf="loading || selectItems.length > 0">
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex">
            <b>{{'admin.form-settings-modal.customer-data' | translate }}</b>
          </div>
        </p-divider>
      </div>
    
      <div class="col-12 border-solid border-1 border-round" style="border-color: #CED4DA;" [class.loading-box]="loading">
        <div
          *ngFor="let item of selectItems; index as i"
          [class.loading-box]="loadingItem[i]"
          formArrayName="selectedUserData"
          class="field-checkbox"
          [class.mt-3]="i===0"
          (mouseenter)="onSelectItemMouseHover(i, 'enter')"
          (mouseleave)="onSelectItemMouseHover(i, 'leave')"
        >
          <p-checkbox name="userDataGroup" [label]="item.title!" [binary]="true" [formControlName]="i"></p-checkbox>
          <span *ngIf="visibleEditButton[i]" class="flex flex-auto justify-content-end gap-3">
            <i class="pi pi-pencil cursor-pointer" style="font-size: 1.5rem; color: var(--text-color-secondary)" (click)="onEditUserData(i)"></i>
            <i class="pi pi-trash cursor-pointer" style="font-size: 1.5rem; color: var(--text-color-secondary)" (click)="onRemoveFromUserData(i)"></i>
          </span>
        </div>
      </div>
    </ng-container>

  </div>
</div>


<div class="modal-footer" *ngIf="selectItems.length > 0">
  <button
    [loading]="saving"
    [disabled]="saving"
    (click)="onSubmit()"
    pButton
    label="{{'admin.form-settings-modal.add-selected-items' | translate }}">
  </button>

</div>
