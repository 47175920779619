import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerCardStoreService, ICustomerCardState } from 'src/app/shared/services/store/customer-card-store.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { AttendingItemComponent } from './attending-item/attending-item.component';
import { ExcusedForReplacementItemComponent } from './excused-for-replacement-item/excused-for-replacement-item.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-card-attendance',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    ProgressSpinnerModule, ButtonModule, PanelModule,
    AttendingItemComponent, ExcusedForReplacementItemComponent
  ],
  templateUrl: './customer-card-attendance.component.html',
  styleUrls: ['./customer-card-attendance.component.scss']
})
export class CustomerCardAttendanceComponent implements OnInit {

  state!: ICustomerCardState;

  constructor(
    public customerCardStore: CustomerCardStoreService,
  ) {
    this.state = this.customerCardStore.state;
  }

  ngOnInit(): void {

  }
}
