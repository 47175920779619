import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReserverMagicCodeService {

  private uuid$ = new BehaviorSubject<string | null>(null);
  private LOCALSTORAGE_KEY = 'LEKTORY_reserverMagicCodeUuid';

  constructor() {
    const savedUuid = this.loadUuid();
    this.setUuid( savedUuid ? savedUuid : null );
  }

  public setUuid(c: string | null) {
    this.saveUuid(c);
    if (c === this.uuid$.getValue()) return;
    this.uuid$.next(c);
  }

  public getUuid$() {
    return this.uuid$;
  }

  private saveUuid(c: string | null) {
    if (c) localStorage.setItem(this.LOCALSTORAGE_KEY, c);
    else localStorage.removeItem(this.LOCALSTORAGE_KEY);
  }

  private loadUuid() {
    return localStorage.getItem(this.LOCALSTORAGE_KEY);
  }
}
