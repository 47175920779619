import { Pipe, PipeTransform } from '@angular/core';
import { IOrgUser, IUser } from '../models/user/user.model';
import { UserHelperService } from '../services/helpers/user-helper.service';
import { SelectedOrgService } from '../services/selected-org.service';

@Pipe({
  name: 'userNameOrEmail',
  standalone: true
})
export class UserNameOrEmailPipe implements PipeTransform {

  constructor(
    private uHelper: UserHelperService,
    private selectedOrgService: SelectedOrgService
  ) {}

  transform(user: (Partial<IUser> & { orgUsers: IOrgUser[]; }) | null | undefined, orgId: number | null | 'CURRENT'): string {
    if (orgId === 'CURRENT') orgId = this.selectedOrgService.getCurrentValue();
    const orgUser = user ? this.uHelper.getOrgUser(user, orgId) : null;
    return orgUser ? this.uHelper.getFullName(orgUser) : `${user?.email}`;
  }

}
