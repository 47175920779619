


<ng-container *ngIf="reservation as r">

  <td
    app-row-index-cell
    [rowIndex]="rowIndex"
    [reservation]="r"
    class="table-col-min-width">
  </td>

  <td
    app-shop-item-cell
    [reservation]="r">
  </td>

  <td
    app-type-cell
    [reservation]="r">
  </td>

  <td
    app-price-cell
    [reservation]="reservation">
  </td>

  <td
    app-order-cell
    [reservation]="reservation">
  </td>

  <td
    app-tag-cell
    [reservation]="r">
  </td>

  <td>
    <!-- todo -->
  </td>

</ng-container>

