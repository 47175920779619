import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';

@Component({
  selector: 'td[app-row-index-cell]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './row-index-cell.component.html',
  styleUrls: ['./row-index-cell.component.scss']
})
export class RowIndexCellComponent {

  @Input() rowIndex: number | undefined;
  _reservation: IReservation | undefined;
  get reservation() { return this._reservation; };
  @Input() set reservation(val: IReservation | undefined) {
    this._reservation = val;
    this.calcCheckedInStates();
  };
  @Input() isSelected: boolean | null = null;

  fullyCheckedIn: boolean = false;
  partiallyCheckedIn: boolean = false;

  calcCheckedInStates() {
    if (this.reservation?.checkin.every(x => x.checkedIn)) this.fullyCheckedIn = true;
    else if (this.reservation?.checkin.some(x => x.checkedIn)) this.partiallyCheckedIn = true;
    else {
      this.fullyCheckedIn = false;
      this.partiallyCheckedIn = false;
    }
  }

}
