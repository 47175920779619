import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { PAYMENT_GATE_REDIRECT_IFRAME_ACTION_ID, PaymentGatePaymentAction } from '../../payment-gate-redirect.module';
import { SystemService } from 'src/app/shared/services/system.service';

@Component({
  selector: 'app-finish-btn',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    ButtonModule,
  ],
  templateUrl: './finish-btn.component.html',
  styleUrls: ['./finish-btn.component.scss']
})
export class FinishBtnComponent implements OnInit {

  @Input() widthClass: string = 'w-18rem';

  inIframe: boolean = false;

  constructor(
    private sysService: SystemService
  ) {}

  ngOnInit() {
    this.inIframe = this.sysService.isInIframe();
  }

  onFinish() {
    const msg = `${PAYMENT_GATE_REDIRECT_IFRAME_ACTION_ID}|${PaymentGatePaymentAction.FINISH}`;
    window.parent.window.postMessage(msg, '*');
  }

}
