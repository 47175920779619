import { INoteAuthorStaticData } from "../item-note/item-note.model";
import { IUser, IUserRole } from "../user/user.model";

export interface ICustomerNote {
  uuid: string;
  title: string | null;
  content: string;
  authorStaticData: INoteAuthorStaticData;
  state: CustomerNoteState;

  authorId: number;
  author?: IUser;
  customerRoleId: number;
  customerRole?: IUserRole;

  createdAt: string;
  updatedAt: string;
  solvedAt: string | null;
}

export enum CustomerNoteState {
  UNSOLVED = 'UNSOLVED',
  SOLVED = 'SOLVED',
}