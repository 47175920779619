


<app-modal-header
  (close)="close()">
  <span slot="title">{{ 'admin.reservations.choose-expiration-modal.title' | translate }}</span>
</app-modal-header>

<div class="modal-body grid">

  <div class="field col-12 md:col-4">
    <label for="time">{{ 'admin.reservations.choose-expiration-modal.new-date.label' | translate }}</label>
    <p-calendar
      [(ngModel)]="datetime"
      [showTime]="true"
      [minDate]="minDateValue"
      [dateFormat]="'primeng.dateFormat' | translate"
      inputId="expiration"></p-calendar>
  </div>

</div>

<div class="modal-footer">

  <button
    pButton
    (click)="close()"
    label="{{ 'admin.reservations.choose-expiration-modal.cancel-btn.label' | translate }}"
    class="p-button-text p-button-plain">
  </button>

  <button
    pButton
    (click)="onSubmit()"
    label="{{ 'admin.reservations.choose-expiration-modal.confirm-btn.label' | translate }}">
  </button>

</div>
