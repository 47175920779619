

<div class="flex flex-column align-items-center pt-2 h-full">

  <i class="w-4rem h-4rem pi pi-thumbs-up flex-shrink-0 bg-green-500 flex align-items-center justify-content-center p-2 border-circle text-white" style="font-size: 2rem"></i>
  
  <h3 class="my-2 text-dark-blue font-semibold">
    {{ 'payment-gate-payment-status.installment-paid.title' | translate }}
  </h3>

  <p class="mt-0 mb-4 text-bluegray-400">
    {{'payment-gate-payment-status.PAID.sending-email-to' | translate}}<br>
  </p>
  
  <div style="width: 100%; flex: 1 1 auto; overflow-y: auto;">
    <ng-container *ngIf="order && !fetchingOrder">
      <app-order-list-item
        [order]="order"
        [collapsed]="false"
        class="w-full mt-2 text-left order-item-border"
        style="flex: 1 1 auto; overflow-y: auto;">
      </app-order-list-item>
    </ng-container>
    
    <div class="flex justify-content-center align-items-center">
      <p-progressSpinner *ngIf="fetchingOrder"></p-progressSpinner>
    </div>
  </div>

  <div class="mt-3 mb-2 w-full">
    <app-show-invoice-or-orders-btn [order]="order" [singleBtnWidthClass]="'w-18rem'"></app-show-invoice-or-orders-btn>
  </div>
  
  <app-finish-btn widthClass="w-18rem"></app-finish-btn>

</div>
