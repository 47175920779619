


<app-modal-header
  (close)="close()">
  <span slot="title">
    {{ 'admin.order-item-price-modal.title' | translate }}
  </span>
</app-modal-header>

<div class="modal-body">

  <div class="field">
    <p-inputNumber
      appNumberInput
      [formControl]="form"
      [minFractionDigits]="2"
      [maxFractionDigits]="2"
      [suffix]="' ' + currency"
      class="w-full"
      styleClass="w-full"
      inputStyleClass="w-full"
    />
  </div>

</div>


<div class="modal-footer">

  <button
    (click)="close()"
    pButton
    [disabled]="sending"
    label="{{ 'admin.order-item-price-modal.cancel-btn.label' | translate }}"
    class="p-button-sm p-button-text p-button-plain">
  </button>

  <button
    (click)="onSubmit()"
    pButton
    [disabled]="sending"
    [loading]="sending"
    label="{{ 'admin.order-item-price-modal.submit-btn.label' | translate}}"
    class="p-button-sm">
  </button>

</div>