import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Country_CZ } from '../../enums/countries/countries.enum';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';

interface ICountry {
  name: string;
  countryCode: string;
}

@Component({
  selector: 'app-country-dropdown-input',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule,
    DropdownModule,
  ],
  templateUrl: './country-dropdown-input.component.html',
  styleUrls: ['./country-dropdown-input.component.scss']
})
export class CountryDropdownInputComponent implements OnInit {

  @Input({ required: true }) countryFC!: FormControl<string>;
  @Input({ required: true }) countryCodeFC!: FormControl<string>;
  @Input() floatLabel: boolean = true;

  countriesMap: { [countryCode: string]: string } = Object.entries(Country_CZ).reduce((prev, curr) => ({ ...prev, [curr[0]]: curr[1] }), {});

  countries: ICountry[] = Object.entries(Country_CZ).map(([countryCode, country]) => ({
    name: country,
    countryCode: countryCode
  }));

  ngOnInit(): void {
    // TMP, only for testing
    if (!this.countryCodeFC.value) this.countryCodeFC.setValue('CZ');
    this.setCountry(this.countryCodeFC.value);

    // zpetna kompatibilita - nekde tento dropdown nebyl a psalo se to primo - nekdo napsal jinak velka pismena
    // a v tom pripade se value v dropdownu nezobrazovala
    if (this.countryFC?.value?.toLocaleLowerCase() === Country_CZ.CZ.toLocaleLowerCase()) this.countryFC?.setValue(Country_CZ.CZ);
  }

  onCountryChange(event: { originalEvent: Event; value: string; }) {
    this.setCountry(event.value);
  }

  setCountry(countryCode: string) {
    this.countryFC.setValue(this.countriesMap[countryCode]);
  }

}
