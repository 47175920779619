import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesHelperService } from 'src/app/shared/services/helpers/invoices-helper.service';
import { ButtonModule } from 'primeng/button';
import { IOrderFE } from 'src/app/shared/models/order/order-model';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { SystemService } from 'src/app/shared/services/system.service';
import { ITransfer } from 'src/app/shared/models/transfers/transfer.model';
import { TransferState } from 'src/app/shared/enums/transfer/transfer-states.enum';

@Component({
  selector: 'app-show-invoice-or-orders-btn',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    ButtonModule,
  ],
  templateUrl: './show-invoice-or-orders-btn.component.html',
  styleUrls: ['./show-invoice-or-orders-btn.component.scss']
})
export class ShowInvoiceOrOrdersBtnComponent implements OnInit {
  TransferState = TransferState;

  @Input() order: IOrderFE | null = null;
  @Input() transfer: ITransfer | null = null;
  @Input() PG_CODE: string | null = null;
  
  @Input() singleBtnWidthClass: string = 'w-18rem';

  inIframe: boolean = false;

  constructor(
    public invoicesHelper: InvoicesHelperService,
    private navService: NavigationService,
    private sysService: SystemService
  ) {}

  ngOnInit(): void {
    this.inIframe = this.sysService.isInIframe();
  }

  onShowOrderInvoice() {
    if (!this.order) return;
    this.navService.goToOrderInvoice({
      orderUuid: this.order.orderUuid,
      newTab: true,
      orgCustomDomains: this.order.organization.customDomainsVerified
    });
  }

  onGoToCustomerPayment(transferUuid: string) {
    if (!this.order) return;

    this.navService.goToCustomerPayment({
      transferUuid,
      newTab: true,
      orgCustomDomains: this.order.organization.customDomainsVerified
    });
  }

  goToMyOrgOrders() {
    if (!this.order) return;


    if (this.PG_CODE) {
      this.navService.goToCustomerOrdersInOrganizationFromPaymentGate({
        orgId: this.order.organization.id,
        PG_CODE: this.PG_CODE,
        orgCustomDomains: this.order.organization.customDomainsVerified,
      });
    }

  }

  showGoToMyOrgOrdersBtn() {
    return this.order && (this.PG_CODE);
  }
}
