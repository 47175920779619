import { Injectable } from '@angular/core';
import { SessionAttendanceState } from '../../models/session-attendance/session-attendance.model';

@Injectable({
  providedIn: 'root'
})
export class SessionAttendanceHelperService {

  constructor() { }

  public getSessionAttendanceStatus({
    state,
    replaced,
    excused,
    noAttendanceYet
  }: {
    state: SessionAttendanceState;
    replaced: boolean;
    excused: boolean;
    noAttendanceYet: boolean;
  }) {
    const bgClasses: string[]= [];
    const colorClasses: string[] = [];
    let icon: string | null = null;

    if (noAttendanceYet || state === null) {
      bgClasses.push('session-attendance-status-bg--no-attendance-yet');
      colorClasses.push('session-attendance-status-color--no-attendance-yet');
      icon = 'pi pi-question';
    }
    else if (state === SessionAttendanceState.PRESENT) {
      bgClasses.push('session-attendance-status-bg--present');
      colorClasses.push('session-attendance-status-color--present');
      icon = 'pi pi-check';
    }
    else if (state === SessionAttendanceState.ABSENT && replaced) {
      bgClasses.push('session-attendance-status-bg--replaced');
      colorClasses.push('session-attendance-status-color--replaced');
      icon = 'pi pi-check';
    }
    else if (state === SessionAttendanceState.ABSENT && excused && !replaced) {
      bgClasses.push('session-attendance-status-bg--absent-excused');
      colorClasses.push('session-attendance-status-color--absent-excused');
      icon = 'pi pi-times';
    }
    else if (state === SessionAttendanceState.ABSENT && !excused && !replaced) {
      bgClasses.push('session-attendance-status-bg--absent-not-excused');
      colorClasses.push('session-attendance-status-color--absent-not-excused');
      icon = 'pi pi-times';

    }


    return { bgClasses: bgClasses.join(' '), colorClasses: colorClasses.join(' '), icon };
  }
}
