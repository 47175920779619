import { IScheduleTaskData } from "src/app/pages/admin/org-admin/components/task-scheduler-input/task-scheduler-input.component";
import { IChangeData } from "../_other/change-data.model";
import { IMail } from "../mail/mail.model";
import { IShopItemSettingModuleSendEmailEmailData } from "./shop-item-setting-module-update-data.model";

export enum ShopItemSettingModuleType {
  DESCRIPTION = 'DESCRIPTION',
  PRICE_INVOICING = 'PRICE_INVOICING',
  SESSIONS = 'SESSIONS',
  LECTURERS = 'LECTURERS',
  QUANTITY = 'QUANTITY',
  RESERVATION = 'RESERVATION',
  DISCOUNTS = 'DISCOUNTS',
  NEXT_BEST_OFFERS = 'NEXT_BEST_OFFERS',
  IMAGES = 'IMAGES',
  CATEGORIES = 'CATEGORIES',
  INTERNAL_TAGS = 'INTERNAL_TAGS',
  TEAM_NOTIFICATIONS = 'TEAM_NOTIFICATIONS',
  ACCREDITATION = 'ACCREDITATION',
  WP_SETTINGS = 'WP_SETTINGS',
  AUTOMATIC_COMMUNICATION = 'AUTOMATIC_COMMUNICATION',
  CERTIFICATES = 'CERTIFICATES',
  ATTENDANCE = 'ATTENDANCE',
  PUBLIC_TAGS = 'PUBLIC_TAGS',
  ITEM_NUMBER = 'ITEM_NUMBER',
  CUSTOM_TEXT = 'CUSTOM_TEXT',
  SEND_EMAIL = 'SEND_EMAIL',
  
  // not impleted yet
  RESERVATION_CONFIRMATION = 'RESERVATION_CONFIRMATION',
  COORDINATORS = 'COORDINATORS',
  CUSTOM_PARAMETER = 'CUSTOM_PARAMETER',
  FINAL_REPORT_AND_EVALUATION = 'FINAL_REPORT_AND_EVALUATION',
  FOLLOW_UP_COURSES = 'FOLLOW_UP_COURSES',
  PREREQUISITES = 'PREREQUISITES',
  STORNO_FEE = 'STORNO_FEE',
  MATERIALS = 'MATERIALS',
  TIME_ALLOCATION = 'TIME_ALLOCATION',
  CALENDAR = 'CALENDAR',
  // /
}

type IShopItemSettingModuleBase = {
  uuid: string; // unique only in shopitem (not globally unique)
  type: ShopItemSettingModuleType;
  uiIndex: number;
  historyEvents: IShopItemSettingModuleHistoryEvent[];
  customTitle?: string | null;
}
export type IShopItemSettingModule = IShopItemSettingModuleBase | IShopItemSettingModuleCustomText;
export type IShopItemSettingModuleCustomText = IShopItemSettingModuleBase & {
  type: ShopItemSettingModuleType.CUSTOM_TEXT;
  data?: {
    html: string | null;
    customId: string | null;
  }
}
export type IShopItemSettingModuleSendEmail = IShopItemSettingModuleBase & {
  type: ShopItemSettingModuleType.SEND_EMAIL;
  data?: {
    scheduleData: IScheduleTaskData;
    emailData: IShopItemSettingModuleSendEmailEmailData;
  }
}

export enum ShopItemSettingModuleHistoryEvent {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  UPDATED = 'UPDATED',
}
export interface IShopItemSettingModuleHistoryEvent extends IChangeData {
  event: ShopItemSettingModuleHistoryEvent; // what happened
  data?: {
  };
}