


<div *ngIf="item" class="py-2">

  <app-user-shop-item-session-attendance
    [shopItem]="item.shopItem"
    [sessionAttendances]="item.sessionAttendances"
    [reservation]="item.reservation">
  </app-user-shop-item-session-attendance>

</div>