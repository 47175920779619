import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Currency } from '../enums/price/currencies.enum';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService implements OnDestroy {

  private currency$ = new BehaviorSubject<Currency>(Currency.CZK);
  private LOCALSTORAGE_KEY = 'LEKTORY_currency';


  constructor(
    private appService: AppService
  ) {
    const browserLanguage = window.navigator.language;
    // TODO brat language z browseru a currency delat podle toho

    window.addEventListener('lektory_currency', this.currencyListenerFnc, false);

    this.init();
  }

  private init(sendEvent: boolean = true) {
    const savedCurrency = this.loadCurrency();
    this.setCurrency( savedCurrency ? <Currency>savedCurrency : Currency.EUR , sendEvent);
  }

  public setCurrency(c: Currency, sendEvent: boolean = true) {
    this.currency$.next(c);
    this.saveCurrency(c);

    if (sendEvent) {
      window.dispatchEvent(new CustomEvent('lektory_currency', { detail: this.appService.appInstanceId }));
    }
  }

  public getCurrency$() {
    return this.currency$;
  }

  public getCurrentCurrency() {
    return this.currency$.getValue();
  }

  private saveCurrency(c: Currency) {
    localStorage.setItem(this.LOCALSTORAGE_KEY, c);
  }

  private loadCurrency() {
    return localStorage.getItem(this.LOCALSTORAGE_KEY);
  }

  private currencyListenerFnc = (e: any) => {
    const customEvent = e as CustomEvent<any>;
    if (customEvent.detail.appInstanceId !== this.appService.appInstanceId) {
      this.init(false);
    }
  };


  ngOnDestroy() {
    window.removeEventListener('lektory_currency', this.currencyListenerFnc);
  }

}
