import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IUser } from 'src/app/shared/models/user/user.model';
import { AgePipe } from 'src/app/shared/pipes/age.pipe';
import { IAdditionalUserData } from 'src/app/shared/services/store/org-reservations-store.service';
import { Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipCopyDirective } from 'src/app/shared/directives/tooltip-copy.directive';
import { UserNameOrEmailPipe } from 'src/app/shared/pipes/user-name-or-email.pipe';

@Component({
  selector: 'td[app-beneficiary-cell]',
  standalone: true,
  imports: [CommonModule, AgePipe, TranslateModule, TooltipCopyDirective, UserNameOrEmailPipe],
  templateUrl: './beneficiary-cell.component.html',
  styleUrls: ['./beneficiary-cell.component.scss']
})
export class BeneficiaryCellComponent {

  @Input({required: true}) beneficiaryUser: IUser | undefined;
  @Input({required: true}) organizationId: number | null = null;
  @Input() additionalUserData: IAdditionalUserData | undefined;
  @Input() hasAnyCustomerNotes: boolean = false;

  @Input() beneficiaryClickListener: Subject<IUser | undefined> | undefined;
  @Input() commentClickListener: Subject<IUser | undefined> | undefined;


}
