import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, finalize, Subscription, take } from 'rxjs';
import { RoleType } from '../../enums/user/role-types.enum';
import { IOrganization } from '../../models/organization/organization.model';
import { IUserRole } from '../../models/user/user.model';
import { OrganizationsService } from '../entities/organizations/organizations.service';
import { UtilsService } from '../utils.service';
import { StoreService } from './store.service';
import { UserStoreService } from './user-store.service';

export interface ISelectableOrgsState {
  orgs$: BehaviorSubject<IOrganization[]>;

  fetchingOrgs$: BehaviorSubject<boolean>;

};


@Injectable({
  providedIn: 'root'
})
export class SelectableOrgsStoreService implements OnDestroy {

  private initState: ISelectableOrgsState = {
    orgs$: new BehaviorSubject<IOrganization[]>([]),

    fetchingOrgs$: new BehaviorSubject<boolean>(false)
  };
  private state: ISelectableOrgsState = this.utilsService.initializeState(this.initState);

  private userRoles: IUserRole[] = [];

  userSub: Subscription = Subscription.EMPTY;
  subs: Subscription[] = [];

  constructor(
    userStore: UserStoreService,
    private organizationsService: OrganizationsService,
    private utilsService: UtilsService,
    store: StoreService,
  ) {
    this.userSub = userStore.state.user$.subscribe(user => {
      this.userRoles = user?.roles ?? [];
      this.init();
    });

    // store actions
    this.subs.push(
      // organization updated - update it also here in this state
      store.actions.organization_organizationUpdated$.subscribe(org => {
        // if null (unknown which one => reinit)
        if (org === null) this.init();
        else {
          const orgs = [...this.state.orgs$.getValue()];
          const orgToUpdateIndex = orgs.findIndex(x => x.id === org.id);
          if (orgToUpdateIndex >= 0) orgs[orgToUpdateIndex] = org;
          this.state.orgs$.next(orgs);
        }
      }),
      //
    );
  }

  public init() {
    this.reset();
    const allOrgIds = [ ...new Set(this.userRoles.filter(x => x.organizationId).map(x => x.organizationId!)) ];
    const isSysAdmin = this.userRoles.some(x => x.type === RoleType.SYSTEM_ADMIN);
    const fnc = isSysAdmin ? this.organizationsService.getForSysAdmin() : this.organizationsService.getByIds(allOrgIds);
    this.state.fetchingOrgs$.next(true);
    fnc.pipe(
      take(1),
      finalize(() => this.state.fetchingOrgs$.next(false))
    ).subscribe({
      next: (res) => {
        this.state.orgs$.next(res);
      }
    });
  }

  private reset() {
    this.utilsService.resetState(this.initState, this.state);
  }

  public getState() {
    return this.state;
  }


  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this.userSub.unsubscribe();
  }
}
