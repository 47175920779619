import { Injectable } from '@angular/core';
import { IOrderFE } from '../../models/order/order-model';

@Injectable({
  providedIn: 'root'
})
export class InvoicesHelperService {

  constructor() { }

  getShowInvoiceBtn(o: IOrderFE | undefined | null): boolean {
    if (!o) return false;

    if (o.invoices.length) return true;

    return false;
  }

}
