import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionGroupRegistrationType } from 'src/app/shared/enums/session-group/session-group-registration-types.enum';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { UserNameOrEmailPipe } from 'src/app/shared/pipes/user-name-or-email.pipe';
import { SessionGroupCoupleRole } from 'src/app/shared/enums/session-group/session-group-couple-roles.enum';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'td[app-type-cell]',
  standalone: true,
  imports: [
    CommonModule,
    NgbTooltipModule, TranslateModule,
    UserNameOrEmailPipe
  ],
  templateUrl: './type-cell.component.html',
  styleUrls: ['./type-cell.component.scss']
})
export class TypeCellComponent {
  SessionGroupRegistrationType = SessionGroupRegistrationType;
  SessionGroupCoupleRole = SessionGroupCoupleRole;

  @Input() reservation: IReservation | undefined;


}
