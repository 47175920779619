import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ActionBy } from 'src/app/shared/enums/utils/action-by.enum';
import { IOrder, IOrderFE } from 'src/app/shared/models/order/order-model';
import { CallableNames, DbService } from '../../db.service';
import { IInvoice } from 'src/app/shared/models/invoice/invoice.model';

export interface IOrderGetByUserData {
  // by user
  userId?: number;
  magicCodeUuid?: string;
  userTransferPG_CODE?: string; // chci objednavky uzivatele ktery ma transfer s timto pg_code
  transferPG_CODE?: string; // chci objednavku ktera ma transfer s timto pg_code
  // /

  // both for by user and filter
  orderUuid?: string;
  // /

  // filters
  orgId?: number;
  orgUuid?: string;
  offerId?: number;
  // /
};

export interface IOrderSetExpirationData {
  orderId: number;
  datetime: string;
};

export interface IOrderSetDatesData {
  orderId: number;
  maturity: string;
  expiration: string
};

export interface IOrderCancelPartialData {
  reservationIds: number[];
  stornoFee?: number | null;
};

export interface IOrderCancelData {
  orderId: number;
  actionBy: ActionBy;
  note: string | null;
};

export interface IOrderGetByUuidData {
  orderUuid: string;
};

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private dbService: DbService,
  ) { }

  public getByUuid(data: IOrderGetByUuidData): Observable<IOrderFE> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrdersGetByUuid, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getByUser(data: IOrderGetByUserData, allSilent?: boolean): Observable<IOrderFE[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrdersGetByUser, data });
    return this.dbService.handleObs(obs, { succSilent: true, allSilent });
  }

  public getSingleByUser(data: IOrderGetByUserData): Observable<IOrderFE> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrdersGetByUser, data });
    return this.dbService.handleObs(obs, { succSilent: true }).pipe(
      map(x => {
        return x[0] ?? null;
      })
    );
  }

  public restoreOrder(orderId: number): Observable<IOrder> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrdersRestore, data: { orderId } });
    return this.dbService.handleObs(obs);
  }

  public cancelOrders(data: IOrderCancelData[]) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrdersCancel, data });
    return this.dbService.handleObs(obs);
  }

  public setDates(data: IOrderSetDatesData[]) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrdersSetDates, data });
    return this.dbService.handleObs(obs);
  }

  public setExpirations(data: IOrderSetExpirationData[]) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrdersSetExpiration, data });
    return this.dbService.handleObs(obs);
  }

  public getFinalInvoice(data: { orderId: number }): Observable<IInvoice | null> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOrdersGetFinalInvoice, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
