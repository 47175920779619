import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from 'src/app/shared/services/entities/orders/orders.service';
import { finalize, take } from 'rxjs';
import { IOrderFE } from 'src/app/shared/models/order/order-model';
import { OrderListItemComponent } from 'src/app/shared/components/order-list-item/order-list-item.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ShowInvoiceOrOrdersBtnComponent } from '../components/show-invoice-or-orders-btn/show-invoice-or-orders-btn.component';
import { FinishBtnComponent } from '../components/finish-btn/finish-btn.component';

@Component({
  selector: 'app-installment-paid',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    ProgressSpinnerModule, ButtonModule,
    OrderListItemComponent, ShowInvoiceOrOrdersBtnComponent, FinishBtnComponent,
  ],
  templateUrl: './installment-paid.component.html',
  styleUrls: ['./installment-paid.component.scss']
})
export class InstallmentPaidComponent implements OnInit {

  order: IOrderFE | null = null;

  orderUuid: string | null = null;
  installmentNumber: number | null = null;

  fetchingOrder: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private ordersService: OrdersService,
  ) { }

  ngOnInit(): void {
    this.orderUuid = this.route.snapshot.paramMap.get('orderUuid');
    const installmentNumberStr = this.route.snapshot.paramMap.get('installmentNumber');
    this.installmentNumber = installmentNumberStr ? parseInt(installmentNumberStr) : null;

    // get order
    this.fetchOrder();
    
  }

  fetchOrder() {
    if (!this.orderUuid) return;
    this.fetchingOrder = true;
    this.ordersService.getByUuid({
      orderUuid: this.orderUuid
    }).pipe(
      take(1),
      finalize(() => this.fetchingOrder = false)
    ).subscribe({
      next: order => {
        this.order = order;
      }
    });
  }

}
