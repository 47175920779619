import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from '../modal';
import { ButtonModule } from 'primeng/button';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { AbstractControl, FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { Subscription, take } from 'rxjs';
import { UtilsService } from '../../services/utils.service';
import { DividerModule } from 'primeng/divider';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectItem } from 'primeng/api';
import { ModalService } from '../../services/modal.service';
import { FormFieldSettingsModalComponent } from '../form-field-settings-modal/form-field-settings-modal.component';
import { Operation } from '../../enums/utils/operations.enum';
import { ICollectedUserData } from '../../models/collecting-data/collected-user-data.model';
import { CollectingFrom } from '../../enums/collecting-data/collecting-from.enum';
import { DataLevel } from '../../enums/collecting-data/data-level.enum';
import { RoleType } from '../../enums/user/role-types.enum';
import { IFormFieldSettings } from '../../models/shop-item/form-field-settings.model';
import { ShopItemsService } from '../../services/entities/shop-items/shop-items.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

interface IFormSettingsForm {
  newTitle: FormControl<string>;
  selectedUserData: FormArray<FormControl<boolean>>;
};

@Component({
  selector: 'app-form-settings-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule,
    ButtonModule, InputTextModule, DividerModule, CheckboxModule,
    ModalHeaderComponent
  ],
  templateUrl: './form-settings-modal.component.html',
  styleUrls: ['./form-settings-modal.component.scss']
})
export class FormSettingsModalComponent extends Modal implements OnInit, OnDestroy {
  @Input() shopItemId: number | null = null;
  @Input() collectingFrom: CollectingFrom | null = null;
  @Output() result = new EventEmitter<{ formFieldSettings: IFormFieldSettings[] }>();

  form = new FormGroup<IFormSettingsForm>({
    newTitle: new FormControl('', { nonNullable: true }),
    selectedUserData: new FormArray<FormControl<boolean>>([])
  });

  collectedUserData: ICollectedUserData[] = [];

  saving = false;
  loading = false;

  selectItems: SelectItem[] = [];
  visibleEditButton: boolean[] = [];
  loadingItem: boolean[] = [];

  subs: Subscription[] = [];

  constructor(
    private utilsService: UtilsService,
    private modalService: ModalService,
    private shopItemService: ShopItemsService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.shopItemId) {
      console.error('missing shopItemId');
      return;
    }
    if (!this.collectingFrom) {
      console.error('missing collectingFrom');
      return;
    }

    this.fetchData();

    this.watchForm();
  }

  fetchData() {
    // fetch unusedCollectedUserData
    this.loading = true;
    this.selectItems.length = 0;
    this.loadingItem.length = 0;
    this.visibleEditButton.length = 0;
    this.collectedUserData.length = 0;
    this.form.controls.selectedUserData.clear();
    this.shopItemService.getUnusedCollectedUserData(this.shopItemId!).pipe(take(1)).subscribe((res) => {
      this.collectedUserData = res.map((x) => x.data);
      res.forEach((x) => {
        const newFC = new FormControl<boolean>(false, { nonNullable: true });
        if (this.collectingFrom && x.used[this.collectingFrom]) newFC.disable();
        this.form.controls.selectedUserData.push(newFC);
        this.selectItems.push({ value: x.data.id, title: `${x.data.title} (${this.translate.instant('DataType.'+x.data.dataType)})` });
        this.loadingItem.push(false);
        this.visibleEditButton.push(false);
      });
      this.loading = false;
      this.form.controls.newTitle.addValidators(formFieldTitleValidator.bind(this, res.map((x) => x.data.title)));
      this.form.updateValueAndValidity();
    });
  }

  watchForm() {

  }

  onSubmit() {
    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid) {
      console.error('invalid');
      return;
    }

    const formVals = this.form.getRawValue();

    const collectedUserDataToSave: ICollectedUserData[] = [];
    formVals.selectedUserData.forEach((selected, index) => {
      if (selected) {
        collectedUserDataToSave.push(this.collectedUserData[index]);
      }
    });
    if (collectedUserDataToSave.length > 0) {
      this.saving = true;
      this.shopItemService.addManyFormSettings({
        shopItemId: this.shopItemId!,
        formFieldSettings: collectedUserDataToSave.map((collectedUserData) => ({
          disabled: false,
          acceptationRequirements: collectedUserData.params.acceptationRequirements || [],
          active: true,
          collectingFrom: this.collectingFrom!,
          dataLevel: DataLevel.USER,
          dataType: collectedUserData.dataType,
          description: collectedUserData.params.description || '',
          id: 0,
          collectedUserDataId: collectedUserData.id,
          immutable: collectedUserData.immutable,
          organizationId: collectedUserData.organizationId,
          title: collectedUserData.title,
          userRoleType: RoleType.CUSTOMER,
          expirationDays: collectedUserData.expirationDays,
          link: collectedUserData.params.link,
          options: collectedUserData.params.options,
          scrollingContent: collectedUserData.params.scrollingContent,
          useAsPrivacyPolicy: collectedUserData.params.useAsPrivacyPolicy,
          useAsTOS: collectedUserData.params.useAsTOS,
          otherOption: collectedUserData.params.otherOption,
          readonlyContent: collectedUserData.params.readonlyContent,
          selectionLimit: collectedUserData.params.selectionLimit,
          useAsBillingInfo: collectedUserData.params.useAsBillingInfo
        }))
      }).pipe(take(1)).subscribe((res) => {
        this.result.emit({ formFieldSettings: res });
        this.close();
      });
    } else {
      this.close();
    }
  }

  onSelect(e: any) {

  }

  onCreate() {
    const name = this.form.controls.newTitle.value;
    const res = this.modalService.openFormFieldSettingsModal(FormFieldSettingsModalComponent, Operation.CREATE, this.shopItemId!, this.collectingFrom!, name, null);
    res.pipe(take(1)).subscribe((res) => {
      this.result.emit({ formFieldSettings: [res.formFieldSettings] });
      this.close();
    });
  }

  onSelectItemMouseHover(i: number, action: 'leave' | 'enter') {
    this.visibleEditButton[i] = action === 'enter' ? true : false;
  }

  onRemoveFromUserData(i: number) {
    const collectedUserData = this.collectedUserData[i];
    this.loadingItem[i] = true;
    this.shopItemService.removeCollectedUserData(collectedUserData.id).pipe(take(1)).subscribe(() => {
      this.collectedUserData.splice(i, 1);
      this.visibleEditButton.splice(i, 1);
      this.loadingItem.splice(i, 1);
      this.form.controls.selectedUserData.removeAt(i);
      this.selectItems.splice(i, 1);
    });
  }

  onEditUserData(i: number) {
    const collectedUserdata = this.collectedUserData[i];
    const res = this.modalService.openFormFieldSettingsModal(FormFieldSettingsModalComponent, Operation.UPDATE, this.shopItemId!, this.collectingFrom!, null, {
      disabled: false,
      acceptationRequirements: [],
      active: false,
      collectingFrom: CollectingFrom.RESERVER,
      dataLevel: DataLevel.USER,
      dataType: collectedUserdata.dataType,
      title: collectedUserdata.title,
      description: collectedUserdata.params.description || '',
      id: 0,
      immutable: collectedUserdata.immutable,
      organizationId: collectedUserdata.organizationId,
      userRoleType: RoleType.CUSTOMER,
      collectedUserDataId: collectedUserdata.id,
      expirationDays: collectedUserdata.expirationDays,
      link: collectedUserdata.params.link,
      options: collectedUserdata.params.options,
      otherOption: collectedUserdata.params.otherOption,
      readonlyContent: collectedUserdata.params.readonlyContent,
      scrollingContent: collectedUserdata.params.scrollingContent,
      selectionLimit: collectedUserdata.params.selectionLimit,
      useAsBillingInfo: collectedUserdata.params.useAsBillingInfo,
      useAsPrivacyPolicy: collectedUserdata.params.useAsPrivacyPolicy,
      useAsTOS: collectedUserdata.params.useAsTOS
    }, true);
    res.pipe(take(1)).subscribe(() => {
      this.fetchData();
    });
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}

export function formFieldTitleValidator(alreadyCreated: string[], control: AbstractControl) {
  const value = control.value as string;
  if (alreadyCreated.includes(value)) {
    return { title: true };
  }

  return null;
}
