import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from '../modal';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectedOrgService } from '../../services/selected-org.service';
import { finalize, Subscription, take } from 'rxjs';
import { OrganizationsService } from '../../services/entities/organizations/organizations.service';
import { IOrganization } from '../../models/organization/organization.model';
import { UtilsService } from '../../services/utils.service';
import { ISetMissingDataData } from '../../models/organization/set-missing-data-data.model';
import { SelectableOrgsStoreService } from '../../services/store/selectable-orgs-store.service';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';

interface IOrgMissingDataForm {
  legalName?: FormControl<string>;
  ico?: FormControl<string>;
};

@Component({
  selector: 'app-org-fill-missing-data-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule,TranslateModule,
    ButtonModule, InputTextModule,
    ModalHeaderComponent,
  ],
  templateUrl: './org-fill-missing-data-modal.component.html',
  styleUrls: ['./org-fill-missing-data-modal.component.scss']
})
export class OrgFillMissingDataModalComponent extends Modal implements OnInit, OnDestroy {

  @Input() orgId: number | undefined;
  @Input() missingFields: (keyof IOrganization)[] = [];

  form = new FormGroup<IOrgMissingDataForm>({ });

  orgIdSub: Subscription = Subscription.EMPTY;

  saving: boolean = false;

  constructor(
    private utilsService: UtilsService,
    private orgsService: OrganizationsService,
    private selectableOrgsStore: SelectableOrgsStoreService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.missingFields.includes('legalName')) this.addLegalNameFC();
  }

  onSubmit() {
    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid || this.saving || !this.orgId) {
      console.error('invalid');
      return;
    }

    const formVals = this.form.getRawValue();
    const data: ISetMissingDataData = {
      orgId: this.orgId,

      legalName: formVals.legalName,
      ico: formVals.ico
    };
    this.saving = true;
    this.orgsService.setMissingData(data).pipe(
      take(1),
      finalize(() => this.saving = false)
    ).subscribe({
      next: (res) => {
        this.selectableOrgsStore.init();
        this.close();
      }
    })
  }

  addLegalNameFC() {
    if (!this.legalNameFC) {
      this.form.addControl('legalName', this.getLegalNameFCtemplate());
    }
  }
  getLegalNameFCtemplate() {
    const FC = new FormControl('', { nonNullable: true, validators: [ Validators.required ]});
    return FC;
  }


  get legalNameFC() {
    return this.form.get('legalName');
  }

  ngOnDestroy(): void {
    this.orgIdSub.unsubscribe();
  }
}
