

@if (message) {
  <div class="message">

    <div class="message-header">
      <div class="flex flex-1 flex-column">

        <div class="flex column-gap-1 align-items-center justify-content-between text-bluegray-300 text-xs">
          <div class="details-block-wrap">
            <span [ngbTooltip]="senderTooltip" class="detail-block">
              <span class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 15ch;">
                {{ parsedFromName }}
              </span>
            </span>
            <span class="detail-block">
              {{ 'admin.message-item.message-type.'+message.channel+'.label' | translate }}
            </span>
          </div>
          <span class="">{{ message.sentAt | localizedDate: 'short' }}</span>
        </div>
        <p class="m-0 mt-1 font-bold line-height-1 text-dark-blue">{{ message.channelContent.subject }}</p>
      </div>
  
    </div>

    <div class="message-actions">

      <!-- <button
        (click)="messageMenu.toggle($event)"
        pButton
        icon="pi pi-ellipsis-h"
        class="p-button-rounded p-button-text p-button-plain p-button-sm">
      </button> -->
      <p-menu #messageMenu [model]="messageMenuItems" [popup]="true" [baseZIndex]="99999999999" appendTo="body"></p-menu>
    </div>
  
    <div class="message-content-wrap">
      @if (message.contentHtml.length) {
        <ng-container *ngTemplateOutlet="messageContentEl; context: { content: message.contentHtml }"></ng-container>
      } @else {
        <ng-container *ngTemplateOutlet="messageContentEl; context: { content: message.contentText }"></ng-container>
      }
    </div>

    <div class="message-bottom-overflow">
      <div class="flex align-items-center justify-content-between">
        <div class="flex">
          

          @if (message.status === 'ERROR') {
            <span [ngbTooltip]="message.errorMessage" class="message-info-tag text-red-500">
              <i class="pi pi-exclamation-circle"></i>
              {{ 'admin.message-item.state.ERROR.label' | translate }}
            </span>
          }
          @if (message.events.length) {
            @if (message.events.length === 1) {
              <span [ngbTooltip]="'admin.message-item.show-event-tooltip' | translate" (click)="onEventClick(message.events[0].uuid)" class="message-info-tag cursor-pointer">
                @if (message.events[0].code; as eventCode) {
                  {{ eventCode }}
                } @else {
                  {{ 'admin.message-item.event' | translate }}
                }
              </span>
            } @else {
              <span class="message-info-tag">
                {{ message.events.length }}x
                {{ 'admin.message-item.event' | translate }}
              </span>
            }
          }
          @if (message.orders.length) {
            @if (message.orders.length === 1) {
              <span [ngbTooltip]="'admin.message-item.show-order-tooltip' | translate" (click)="onOrderClick(+(message.orders[0].id + '' + message.orders[0].randomNumber))" class="message-info-tag cursor-pointer">
                {{ 'admin.message-item.order-number' | translate: {orderNumber: +(message.orders[0].id + '' + message.orders[0].randomNumber) } }}
              </span>
            } @else {
              <span class="message-info-tag">
                {{ message.orders.length }}x
                {{ 'admin.message-item.order' | translate }}
              </span>
            }
          }
          @if (message.reservations.length) {
            @if (message.reservations.length === 1) {
              <span [ngbTooltip]="'admin.message-item.show-reservation-tooltip' | translate" (click)="onReservationClick(message.reservations[0].id)" class="message-info-tag cursor-pointer">
                {{ 'admin.message-item.reservation' | translate }}
              </span>
            } @else {
              <span class="message-info-tag">
                {{ message.reservations.length }}x
                {{ 'admin.message-item.reservation' | translate }}
              </span>
            }
          }

        </div>


        <div
          (click)="contentCollapsed = !contentCollapsed"
          class="content-collapse-toggle flex align-items-center justify-content-center">
          <i
            class=" pi {{ contentCollapsed ? 'pi-chevron-down' : 'pi-chevron-up' }} text-bluegray-400">
          </i>
        </div>

      </div>
    </div>

  </div>
}

<ng-template #messageContentEl let-content="content">
  <p
    [innerHTML]="sanitizeContent(content)"
    [class.collapsed]="contentCollapsed"
    [ngStyle]="{ 'white-space': (isContentHtml(content) ? 'normal' : 'break-spaces') }"
    class="message-content text-bluegray-400 mb-0">
  </p>
</ng-template>

<ng-template #senderTooltip>
  <div class="text-left">
    @if (message?.channelContent?.from; as from) {
      <p class="m-0 mb-2">From: {{ parsedFrom }}</p>
    }
    @if (message?.channelContent?.replyTo?.length) {
      <p class="m-0 mb-2">Reply-to: {{ message?.channelContent?.replyTo?.join(', ') }}</p>
    }
    @if (message?.channelContent?.cc?.length) {
      <p class="m-0 mb-2">cc: {{ message?.channelContent?.cc?.join(', ') }}</p>
    }
    @if (message?.channelContent?.bcc?.length) {
      <p class="m-0 mb-2">bcc: {{ message?.channelContent?.bcc?.join(', ') }}</p>
    }
  </div>
</ng-template>