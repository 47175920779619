import { Pipe, PipeTransform } from '@angular/core';
import { IAddress } from '../modals/address-form-group-modal/address-form-group-modal.component';
import { IOrder, IOrderFE } from '../models/order/order-model';

@Pipe({
  name: 'orderNumber',
  standalone: true,
})
export class OrderNumberPipe implements PipeTransform {

  constructor() {}

  transform(order?: IOrder | IOrderFE | null): string {
    if (!order) return '';
    if ('orderId' in order) {
      return `${order.orderId}${order.randomNumber}`;
    } else {
      return `${order.id}${order.randomNumber}`;
    }
  }
}
