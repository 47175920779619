<app-modal-header
  (close)="close()">
  <span slot="title">
    {{'admin.cancel-reservation-modal.title' | translate }}
  </span>
</app-modal-header>

<div class="modal-body flex flex-column" [formGroup]="form" [class.loading-box]="loading">
  <p>{{'admin.cancel-reservation-modal.subtitle' | translate }}</p>

  <p-timeline [value]="timelineItems">

    <!-- <ng-template pTemplate="marker" let-item>

      <ng-container *ngTemplateOutlet="markerDecider, context:{item:item}"></ng-container>
    </ng-template> -->

    <ng-template pTemplate="content" let-item>
      <div class="flex flex-column collapse-wrap mb-3">
        <div (click)="onItem(item)" class="flex p-3 border-round-top border-1 font-bold cursor-pointer justify-content-between align-center" [class.border-round-bottom]="!!timelineCollapsedMap[item]" style="background: #e9ecef; border-color: #ced4da;">
          <span>
            <ng-container *ngTemplateOutlet="titleDecider, context:{item:item}"></ng-container>
          </span>
          <i [class]="!!timelineCollapsedMap[item] ? 'pi pi-plus' : 'pi pi-minus'" class="align-self-center"></i>
        </div>
        <span [@collapse]="!!timelineCollapsedMap[item]">
          <span class="p-3 flex border-round-bottom border-left-1 border-right-1 border-bottom-1" style="border-color: #ced4da;">
            <ng-container *ngTemplateOutlet="contentDecider, context:{item:item}"></ng-container>
          </span>
        </span>
      </div>
    </ng-template>
  </p-timeline>

  <!-- title decider -->
  <ng-template #titleDecider let-item="item">

    <ng-container *ngIf="item === TimelineItemType.CANCEL_RESERVATION">
      {{'admin.cancel-reservation-modal.TimelineItemType.CANCEL_RESERVATION.title' | translate }}
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.CANCEL_PARTNER_RESERVATION">
      {{'admin.cancel-reservation-modal.TimelineItemType.CANCEL_PARTNER_RESERVATION.title' | translate }} ({{ reservation?.partner | userNameOrEmail:reservation?.organizationId || null }})
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.WAITING_LIST">
      {{'admin.cancel-reservation-modal.TimelineItemType.WAITING_LIST.title' | translate }}
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.DISCOUNTS">
      {{'admin.cancel-reservation-modal.TimelineItemType.DISCOUNTS.title' | translate }}
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.CANCEL_ORDER">
      {{'admin.cancel-reservation-modal.TimelineItemType.CANCEL_ORDER.title' | translate }} #{{ order | orderNumber }}
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.REFUND">
      {{'admin.cancel-reservation-modal.TimelineItemType.REFUND.title' | translate }}
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.NOTIFICATION">
      <span class="flex gap-2">
        <span (click)="onSendNotificationClick($event)">
          <p-checkbox formControlName="sendNotifications" [binary]="true"></p-checkbox>
        </span>
        <span>
          {{'admin.cancel-reservation-modal.TimelineItemType.NOTIFICATION.title' | translate }}
        </span>
      </span>
    </ng-container>
  </ng-template>
  <!-- / title decider -->

  <!-- content decider -->
  <ng-template #contentDecider let-item="item">
    <ng-container *ngIf="item === TimelineItemType.CANCEL_RESERVATION">
      {{'admin.cancel-reservation-modal.TimelineItemType.CANCEL_RESERVATION.description' | translate }}
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.CANCEL_PARTNER_RESERVATION">
      {{'admin.cancel-reservation-modal.TimelineItemType.CANCEL_PARTNER_RESERVATION.description' | translate }}
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.WAITING_LIST">
      {{'admin.cancel-reservation-modal.TimelineItemType.WAITING_LIST.description' | translate }}
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.DISCOUNTS">
      {{'admin.cancel-reservation-modal.TimelineItemType.DISCOUNTS.description' | translate }}
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.CANCEL_ORDER">
      {{'admin.cancel-reservation-modal.TimelineItemType.CANCEL_ORDER.description' | translate }}
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.REFUND">
      <span class="w-full">
        <span>
          {{'admin.cancel-reservation-modal.TimelineItemType.REFUND.description' | translate }}
        </span>
        <div class="flex flex-column mt-2 row-gap-2">
          <div class="flex justify-content-between align-items-center">
            <span>
              {{'admin.cancel-reservation-modal.TimelineItemType.REFUND.itemPrice' | translate }}
            </span>
            <span class="font-bold">
              {{ (form.controls.itemPrice?.value?.value || 0) > 0 ? '+' : '' }}{{ [form.controls.itemPrice!.value] | price | async }}
            </span>
          </div>

          <div class="flex justify-content-between align-items-center">
            <span>
              {{'admin.cancel-reservation-modal.TimelineItemType.REFUND.totalPaid' | translate:{ totalPaidPercentage: (form.controls.totalPaidPercentage?.value || 0) | percent } }}
            </span>
            <span class="font-bold">
              {{ (form.controls.totalPaid?.value || 0) > 0 ? '+' : '' }}{{ [{value: form.controls.totalPaid?.value || 0, currency: orderCurrency}] | price | async }}
            </span>
          </div>

          @if (form.controls.lostDiscounts?.value) {
            <div class="flex justify-content-between align-items-center">
              <span>
                {{'admin.cancel-reservation-modal.TimelineItemType.REFUND.lostDiscounts' | translate }}
              </span>
              <span class="font-bold">
                -{{ [{value: form.controls.lostDiscounts?.value || 0, currency: orderCurrency}] | price | async }}
              </span>
            </div>
          }

          <div class="flex justify-content-between align-items-center">
            <span>
              {{'admin.cancel-reservation-modal.TimelineItemType.REFUND.stornoFee' | translate }}
            </span>
            <div>
              <span class="font-bold">-</span>
              <p-inputNumber
                appNumberInput
                *ngIf="form.controls.stornoFee"
                [formControl]="form.controls.stornoFee"
                mode="decimal"
                [min]="0"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                [inputStyle]="{'width': '6ch', 'box-sizing': 'content-box', 'text-align': 'right'}"
              />
              <span class="font-bold">
                {{ getCurrencySymbol(orderCurrency, 'narrow', 'cs-CZ') }}
              </span>
            </div>
          </div>

          <p-divider></p-divider>

          <div class="flex justify-content-between align-items-center">
            <span>
              {{'admin.cancel-reservation-modal.TimelineItemType.REFUND.refundAmount' | translate }}
            </span>
            <span class="font-bold">
              {{ (form.controls.refundAmount?.value || 0) > 0 ? '+' : '' }}{{ [{value: form.controls.refundAmount?.value || 0, currency: orderCurrency}] | price | async }}
            </span>
          </div>
        </div>

        <!--  -->

        <!-- <div class="flex justify-content-between align-items-center mt-2">
          <div class="col-3 p-0 justify-content-center flex flex-column align-items-center">
            <p-inputNumber
              appNumberInput
              *ngIf="form.controls.totalPaid"
              [formControl]="form.controls.totalPaid"
              [suffix]="(' ' + reservation?.orderItem?.currency) || ''"
              inputStyleClass="w-full"
            />
          </div>
          <i class="pi pi-minus"></i>
          <div class="col-3 p-0 flex flex-column align-items-center">
            <p-inputNumber
              appNumberInput
              *ngIf="form.controls.stornoFee"
              [formControl]="form.controls.stornoFee"
              mode="decimal"
              [min]="0"
              [max]="form.controls.totalPaid?.value || 0"
              [minFractionDigits]="0"
              [maxFractionDigits]="2"
              [suffix]="(' ' + reservation?.orderItem?.currency) || ''"
              inputStyleClass="w-full"
            />
          </div>
          <i class="pi pi-pause rotate-90"></i>
          <div class="col-3 p-0 flex flex-column align-items-center">
            <p-inputNumber
              appNumberInput
              *ngIf="form.controls.refundAmount"
              [formControl]="form.controls.refundAmount"
              [suffix]="(' ' + reservation?.orderItem?.currency) || ''"
              inputStyleClass="w-full"
            />
          </div>
        </div>
        <div class="flex justify-content-between align-items-center">
          <div class="col-3 pl-0 pr-0 pt-1 justify-content-center flex flex-column align-items-center">
            <span class="text-xs">{{'admin.cancel-reservation-modal.TimelineItemType.REFUND.totalPaid' | translate }}</span>
          </div>
          <i class="pi pi-minus hidden"></i>
          <div class="col-3 pl-0 pr-0 pt-1 flex flex-column align-items-center">
            <span class="text-xs">{{'admin.cancel-reservation-modal.TimelineItemType.REFUND.stornoFee' | translate }}</span>
          </div>
          <i class="pi pi-pause rotate-90 hidden"></i>
          <div class="col-3 pl-0 pr-0 pt-1 flex flex-column align-items-center">
            <span class="text-xs">{{'admin.cancel-reservation-modal.TimelineItemType.REFUND.refundAmount' | translate }}</span>
          </div>
        </div> -->
        @if ((form.controls.refundAmount?.value || 0) > 0) {
          <span class="flex gap-2 flex-column" *ngIf="form.controls.refundToCredit">
            <p-divider></p-divider>
            <span>
              {{ 'admin.cancel-reservation-modal.TimelineItemType.REFUND.label' | translate }}
            </span>
            <span>
              <label
                *ngFor="let refundOption of refundOptions; index as i;"
                for="refund-option-{{i}}"
                class="surface-border border-1 p-3 mb-0 surface-0 flex align-items-center gap-3 cursor-pointer"
                [class.border-round-top]="i === 0"
                [class.border-round-bottom]="i === (refundOptions.length || 0) - 1"
                [class.border-top-none]="i !== 0"
              >
                <!-- <p-checkbox formControlName="refundToCredit" [binary]="true"></p-checkbox> -->
                <p-radioButton inputId="refund-option-{{i}}" formControlName="refundToCredit" [value]="refundOption.value"></p-radioButton>
                <div>
                  <div class="text-xl">{{ refundOption.title! | translate }}</div>
                  <div class="text-xs text-bluegray-400">{{ refundOption.label! | translate }}</div>
                </div>
              </label>
            </span>
            <!-- <span>
              {{'admin.cancel-reservation-modal.TimelineItemType.REFUND.refundToCredit' | translate }}
            </span> -->
          </span>
        }
      </span>
    </ng-container>

    <ng-container *ngIf="item === TimelineItemType.NOTIFICATION">
      <span class="flex flex-column">
        <ng-container *ngIf="form.controls.sendNotifications.value">
          <span>{{'admin.cancel-reservation-modal.TimelineItemType.NOTIFICATION.on.description' | translate }}</span>
          <span>{{ emails }}</span>
        </ng-container>
        <ng-container *ngIf="!form.controls.sendNotifications.value">
          <span>{{'admin.cancel-reservation-modal.TimelineItemType.NOTIFICATION.off.description' | translate }}</span>
        </ng-container>
      </span>
    </ng-container>
  </ng-template>
  <!-- / content decider -->


  <!-- marker -->
  <ng-template #markerEl let-icon="icon" let-color="color">
    <span class="p-timeline-event-marker shadow-2" [style.color]="'var('+color+')'">
      <i [ngClass]="icon"></i>
    </span>
  </ng-template>
  <!-- / marker -->
</div>

<div class="modal-footer justify-content-between">

  <button (click)="close()" pButton label="{{'admin.cancel-reservation-modal.close' | translate }}" class="p-button-text p-button-plain"></button>
  <button (click)="onSubmit()" pButton label="{{'admin.cancel-reservation-modal.cancel' | translate }}" icon="pi pi-times" class="p-button-danger"></button>

</div>
