import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from 'src/app/shared/modals/modal';
import { ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { IReservationChangeShopItemData, ReservationsService } from 'src/app/shared/services/entities/reservations/reservations.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { ICandidateForShopItemChange, IShopItemIShopItemGetForReservationShopItemChangeResult, ShopItemsService } from 'src/app/shared/services/entities/shop-items/shop-items.service';
import { Subscription, finalize, take } from 'rxjs';
import { ListboxModule } from 'primeng/listbox';
import { ShopItemQuickInfoComponent } from 'src/app/shared/components/shop-item-components/shop-item-quick-info/shop-item-quick-info.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomDataInputsComponent } from 'src/app/pages/admin/components/custom-data-inputs/custom-data-inputs.component';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ReservationState } from 'src/app/shared/enums/reservation/reservation-states.enum';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { StoreService } from 'src/app/shared/services/store/store.service';
import { SelectItem } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SessionGroupCoupleRole } from 'src/app/shared/enums/session-group/session-group-couple-roles.enum';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SessionGroupRegistrationType } from 'src/app/shared/enums/session-group/session-group-registration-types.enum';
import { emailValidator } from 'src/app/shared/utils/session-group-form-utils/validators';
import { SelectedOrgService } from 'src/app/shared/services/selected-org.service';
import { daysString } from 'src/app/shared/components/date-input/date-input.component';
import { NumberInputDirective } from 'src/app/shared/directives/number-input.directive';
import { IMessageTemplate, MessageTemplateName, MessageTemplateService } from 'src/app/shared/services/entities/message-template/message-template-service';
import { TinymceEditorComponent } from 'src/app/shared/components/tinymce-editor/tinymce-editor.component';

interface IChangeShopItemModalForm {
  reservationState: FormControl<ReservationState>;

  daysToExpire: FormControl<number>;
  checkWL: FormControl<boolean>;
  adminNote: FormControl<string | null>;
  sendNotification: FormControl<boolean>;
  notification?: FormGroup<{
    replyTo: FormControl<string>;
    subject: FormControl<string>;
    template: FormControl<string>;
  }>;
  sessionGroupCoupleRole?: FormControl<SessionGroupCoupleRole | null>;
}

@Component({
  selector: 'app-change-shopitem-modal',
  standalone: true,
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule,
    ProgressSpinnerModule, ButtonModule, ListboxModule, InputTextModule, CheckboxModule, InputNumberModule, DropdownModule, SelectButtonModule,
    ModalHeaderComponent, ShopItemQuickInfoComponent, CustomDataInputsComponent, TranslateModule, TinymceEditorComponent, NumberInputDirective
  ],
  templateUrl: './change-shopitem-modal.component.html',
  styleUrls: ['./change-shopitem-modal.component.scss']
})
export class ChangeShopitemModalComponent extends Modal implements OnInit, OnDestroy {
  daysString = daysString;

  @Input() reservationId: number | undefined;

  form = new FormGroup<IChangeShopItemModalForm>({
    reservationState: new FormControl<ReservationState>(ReservationState.APPROVED, { nonNullable: true }),

    daysToExpire: new FormControl<number>(3, { nonNullable: true, validators: [ Validators.min(1), Validators.required ]}),
    checkWL: new FormControl<boolean>(true, { nonNullable: true }),
    adminNote: new FormControl<string | null>(null),
    sendNotification: new FormControl<boolean>(false, { nonNullable: true })
  });

  editorSettings = {
    menubar: false,
    plugins: 'lists link',
    toolbar: 'bold italic underline | bullist | link | removeformat | lektoryTemplatesButton',
    language: this.translate.currentLang,
    entity_encoding: "raw" as any,
    init_instance_callback : (ed: any) => {
      // nevim proc, ale po initu se nastavil control na dirty
      this.form?.markAsPristine();
    }
  };

  possibleReservationStates: SelectItem[] = [
    {
      value: ReservationState.APPROVED,
      label: 'admin.reservations.change-shopitem.modal.possible-state.approved'
    },
    {
      value: ReservationState.WAITING,
      label: 'admin.reservations.change-shopitem.modal.possible-state.waiting'
    }
  ];
  sessionGroupCoupleRoleOptions: SelectItem[] = [];

  foundOrgMessageTemplate: IMessageTemplate | null = null;

  candidateData: IShopItemIShopItemGetForReservationShopItemChangeResult | null = null;
  _selectedCandidate: ICandidateForShopItemChange | null = null;
  get selectedCandidate() { return this._selectedCandidate; }
  set selectedCandidate(val: ICandidateForShopItemChange | null) {
    this._selectedCandidate = val;
    this.createSessionGroupCoupleRoleOptions();
  }

  // mappedCustomDataFG = this.utilsService.createCustomDataFG([]);
  // unmappableCustomDataFG = this.utilsService.createCustomDataFG([]);

  fetchingCandidateShopItems: boolean = false;
  fetchingMessageTemplate: boolean = false;
  sending: boolean = false;

  subs: Subscription[] = [];

  constructor(
    private reservationsService: ReservationsService,
    private shopItemsService: ShopItemsService,
    private utilsService: UtilsService,
    private storeService: StoreService,
    private translate: TranslateService,
    private selectedOrgService: SelectedOrgService,
    private messageTemplateService: MessageTemplateService
  ) {
    super();

    this.subs.push(
      this.translate.onLangChange.subscribe(() => this.createSessionGroupCoupleRoleOptions())
    );
  }

  ngOnInit(): void {
    this.fetchCandidateShopItems();
    this.fetchMessageTemplate();
    this.subs.push(
      this.form.controls.sendNotification.valueChanges.subscribe((sendNotifications) => {
        if (sendNotifications) {
          this.form.addControl('notification', new FormGroup({
            replyTo: new FormControl<string>(this.selectedOrgService.selectedOrg$.getValue()?.email || '', { nonNullable: true, validators: [Validators.required, emailValidator] }),
            // TODO: what to do with other languages?
            subject: new FormControl<string>(this.foundOrgMessageTemplate?.subjects['cs'] || '', { nonNullable: true, validators: [Validators.required] }),
            template: new FormControl<string>(this.foundOrgMessageTemplate?.htmls['cs'] || '', { nonNullable: true, validators: [Validators.required] })
          }));
        } else {
          this.form.removeControl('notification');
        }
      })
    );
  }

  onSubmit() {
    this.utilsService.markFormGroupDirty(this.form);
    if (this.form.invalid || this.sending || !this.reservationId || !this.selectedCandidate) {
      console.error('invalid');
      return;
    }

    const formVals = this.form.getRawValue();
    const d: IReservationChangeShopItemData = {
      reservationId: this.reservationId,
      newShopItemId: this.selectedCandidate.shopItem.id,
      adminNote: formVals.adminNote,
      checkWL: formVals.checkWL,
      daysToExpire: formVals.daysToExpire,
      reservationState: formVals.reservationState,
      sendNotification: formVals.sendNotification,
      notification: formVals.notification ?? null,
      sessionGroupCoupleRole: formVals.sessionGroupCoupleRole ?? null,
    };

    this.sending = true;
    this.reservationsService.changeShopItem(d).pipe(
      take(1),
      finalize(() => this.sending = false)
    ).subscribe({
      next: (newReservationId) => {
        this.storeService.actions.orgReservation_reservationChangedShopItem$.next(newReservationId);
        this.close();
      }
    })
  }

  private fetchMessageTemplate() {
    if (!this.reservationId) return;

    this.fetchingMessageTemplate = true;
    this.messageTemplateService.getOrganizationSpecific({
      reservationId: this.reservationId,
      name: MessageTemplateName.RESERVATION_CHANGED_SHOPITEM,
    }).pipe(
      take(1),
      finalize(() => this.fetchingMessageTemplate = false)
    ).subscribe({
      next: (data) => {
        if (data.length) {
          // TODO: change to only one
          this.foundOrgMessageTemplate = data[0];
        }
      }
    })
  }

  private fetchCandidateShopItems() {
    if (!this.reservationId) return;

    this.fetchingCandidateShopItems = true;
    this.shopItemsService.getForReservationShopItemChange({
      reservationId: this.reservationId
    }).pipe(
      take(1),
      finalize(() => this.fetchingCandidateShopItems = false)
    ).subscribe({
      next: (data) => {
        this.candidateData = data;
      }
    })

  }

  onCandidateChange(e: ICandidateForShopItemChange) {
    this.selectedCandidate = e;

    this.removeSessionGroupRoleFC();
    if (
      this.selectedCandidate?.shopItem.sessionGroup && [SessionGroupRegistrationType.INDIVIDUALS_COUPLE, SessionGroupRegistrationType.COUPLE].includes(this.selectedCandidate?.shopItem.sessionGroup?.registrationType)
    ) {
      this.addSessionGroupRoleFC(this.candidateData?.reservation.sessionGroupCoupleRole ?? null);
    }

    // this.mappedCustomDataFG = this.utilsService.createCustomDataFG(e.mappedCustomData);
    // this.mappedCustomDataFG.disable();
    // this.unmappableCustomDataFG = this.utilsService.createCustomDataFG(e.unmappableCustomData);
    // this.unmappableCustomDataFG.disable();

  }

  private addSessionGroupRoleFC(role: SessionGroupCoupleRole | null) {
    if (this.form.controls.sessionGroupCoupleRole) return;
    this.form.addControl('sessionGroupCoupleRole', new FormControl<SessionGroupCoupleRole | null>(role, {  validators: [ Validators.required ]}));
  }
  private removeSessionGroupRoleFC() {
    if (!this.form.controls.sessionGroupCoupleRole) return;
    this.form.removeControl('sessionGroupCoupleRole');
  }

  private createSessionGroupCoupleRoleOptions() {
    if (!this.selectedCandidate?.shopItem.sessionGroup) {
      this.sessionGroupCoupleRoleOptions = [];
      return;
    }
    this.sessionGroupCoupleRoleOptions = [
      {
        value: SessionGroupCoupleRole.A,
        label: this.translate.instant('admin.reservations.change-shopitem.modal.possible-couple-role.a', { name: this.selectedCandidate.shopItem.sessionGroup.roleLabelA })
      },
      {
        value: SessionGroupCoupleRole.B,
        label: this.translate.instant('admin.reservations.change-shopitem.modal.possible-couple-role.b', { name: this.selectedCandidate.shopItem.sessionGroup.roleLabelB })
      },
    ];
  }



  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }
}
