



<ng-container *ngIf="shopItem">

  <div class="flex justify-content-between column-gap-1">
  
    <div class="flex flex-column justify-content-between">
      <h5 class="m-0">
        {{ shopItem.title }}
      </h5>

      <div class="details-block-wrap mb-1 -mt-1">
        <span *ngIf="siHelper.getSessionGroupDate(shopItem.sessionGroup) as sgDate" class="detail-block">
          {{ sgDate | translate }}
        </span>
  
        <span *ngIf="siHelper.getSessionGroupTime(shopItem.sessionGroup) as sgTime" class="detail-block">
          {{ sgTime }}
        </span>
  
        <span *ngIf="siHelper.getSessionGroupLocation(shopItem.sessionGroup, true) as sgLocation" class="detail-block">
          <span [ngbTooltip]="sgLocation" class="text-overflow-ellipsis white-space-nowrap overflow-hidden" style="max-width: 17ch;">
            {{ sgLocation }}
          </span>
        </span>
      </div>
  
      <app-session-attendance-status-bar [sessionAttendances]="sessionAttendances"></app-session-attendance-status-bar>
  
      <app-reservation-status [reservation]="reservation"></app-reservation-status>
      
    </div>
  
  </div>

</ng-container>



