

export enum DiscountType {
  SELECTABLE_BY_CUSTOMER = 'SELECTABLE_BY_CUSTOMER',
  COUPLE = 'COUPLE',
  GROUP = 'GROUP',
  SIBLINGS = 'SIBLINGS',
  QUANTITY = 'QUANTITY',
  ITEM_SPECIFIC = 'ITEM_SPECIFIC',
  FIRST_N_ORDERS = 'FIRST_N_ORDERS',
  PROMO_CODE = 'PROMO_CODE'
};
