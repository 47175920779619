import { Injectable } from '@angular/core';
import { IOrganization } from '../../models/organization/organization.model';
import { IOrgCustomDomainsVerifiedFE, IOrganizationCustomDomain, OrganizationCustomDomainStatus, OrganizationCustomDomainType } from '../../models/organization/custom-domain.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationHelperService {

  constructor() { }

  
}

export function getOrgCustomDomainsVerifiedForFE(org: IOrganization): IOrgCustomDomainsVerifiedFE {
  const orgCustomDomains = org.customDomains as unknown as IOrganizationCustomDomain[];
  const verifiedOrgCustomDomains = orgCustomDomains.filter(x => x.status === OrganizationCustomDomainStatus.VERIFIED);
  return {
    customerDomain: verifiedOrgCustomDomains.find(x => x.type === OrganizationCustomDomainType.CUSTOMER_SITE)?.domain ?? null,
  };
}