import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionAttendanceStatusBarComponent } from '../session-attendance-status-bar/session-attendance-status-bar.component';
import { ReservationStatusComponent } from '../reservation-status/reservation-status.component';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { ISingleSessionAttendanceFE } from 'src/app/shared/models/session-attendance/session-attendance.model';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { ShopItemHelperService } from 'src/app/shared/services/helpers/shop-item-helper.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-user-shop-item-session-attendance',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    
    SessionAttendanceStatusBarComponent, ReservationStatusComponent,
    NgbTooltipModule,
  ],
  templateUrl: './user-shop-item-session-attendance.component.html',
  styleUrls: ['./user-shop-item-session-attendance.component.scss']
})
export class UserShopItemSessionAttendanceComponent {

  @Input() shopItem: IShopItem | undefined;
  @Input() sessionAttendances: ISingleSessionAttendanceFE[] | undefined;
  @Input() reservation: IReservation | undefined;

  constructor(
    public siHelper: ShopItemHelperService,
  ) {}

}
