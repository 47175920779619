import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from 'src/app/shared/modals/modal';
import { IOrderItemFE } from 'src/app/shared/models/order/order-model';
import { ButtonModule } from 'primeng/button';
import { ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { IDiscountsAppliedChangeCallableData, IOrderItemPriceUpdateData } from 'src/app/shared/services/entities/order-items/order-items.service';
import { finalize, take } from 'rxjs';
import { InputNumberModule } from 'primeng/inputnumber';
import { CustomerCardStoreService } from 'src/app/shared/services/store/customer-card-store.service';
import { TranslateModule } from '@ngx-translate/core';
import { NumberInputDirective } from 'src/app/shared/directives/number-input.directive';

@Component({
  selector: 'app-customer-card-order-order-item-discount-modal',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, TranslateModule,
    ButtonModule, InputNumberModule,
    ModalHeaderComponent, NumberInputDirective
  ],
  templateUrl: './customer-card-order-order-item-discount-modal.component.html',
  styleUrls: ['./customer-card-order-order-item-discount-modal.component.scss']
})
export class CustomerCardOrderOrderItemDiscountModalComponent extends Modal implements OnInit {

  @Input() orderId: number | undefined;
  @Input() reservationId: number | undefined;
  @Input() discountTemplateId: number | undefined;
  @Input() percentValue: number | undefined;

  form = new FormControl<number | null>(null, { validators: [ Validators.required, Validators.min(0) ]});

  sending = false;

  constructor(
    private utilsService: UtilsService,
    private customerCardStore: CustomerCardStoreService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form.patchValue((this.percentValue ?? 0) * 100);
  }

  onSubmit() {
    this.utilsService.markControlDirty(this.form);
    if (this.form.invalid || this.sending) {
      console.error('invalid');
      return;
    }

    if (!this.discountTemplateId || this.percentValue === undefined || !this.reservationId || !this.orderId) return;

    const data: IDiscountsAppliedChangeCallableData = {
      discountTemplateId: this.discountTemplateId,
      percentValue: this.form.value! / 100,
      reservationId: this.reservationId
    }

    this.sending = true;
    this.customerCardStore.updateOrderItemDiscount(data, this.orderId).pipe(
      take(1),
      finalize(() => this.sending = false)
    ).subscribe({
      next: () => {
        this.close();
      }
    });

  }
}
