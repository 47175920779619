export enum Country_CZ {
  CZ = 'Česká republika',
  AF = 'Afghánistán',
  AL = 'Albánie',
  DZ = 'Alžírsko',
  AD = 'Andorra',
  AO = 'Angola',
  AG = 'Antigua a Barbuda',
  AR = 'Argentina',
  AM = 'Arménie',
  AU = 'Austrálie',
  AZ = 'Ázerbájdžán',
  BS = 'Bahamy',
  BH = 'Bahrajn',
  BD = 'Bangladéš',
  BB = 'Barbados',
  MM = 'Barma (Myanmar)',
  BE = 'Belgie',
  BZ = 'Belize',
  BY = 'Bělorusko',
  BJ = 'Benin',
  BT = 'Bhútán',
  BO = 'Bolívie',
  BA = 'Bosna a Hercegovina',
  BW = 'Botswana',
  BR = 'Brazílie',
  BN = 'Brunej',
  BG = 'Bulharsko',
  BF = 'Burkina',
  BI = 'Burundi',
  CK = 'Cookovy ostrovy',
  TD = 'Čad',
  ME = 'Černá Hora',
  CN = 'Čína',
  DK = 'Dánsko',
  CD = 'Demokratická republika Kongo',
  DM = 'Dominica',
  DO = 'Dominikánská republika',
  DJ = 'Džibutsko',
  EG = 'Egypt',
  EC = 'Ekvádor',
  ER = 'Eritrea',
  EE = 'Estonsko',
  ET = 'Etiopie',
  FJ = 'Fidži',
  PH = 'Filipíny',
  FI = 'Finsko',
  FR = 'Francie',
  GA = 'Gabon',
  GM = 'Gambie',
  GH = 'Ghana',
  GD = 'Grenada',
  GE = 'Gruzie',
  GT = 'Guatemala',
  GN = 'Guinea',
  GW = 'Guinea-Bissau',
  GY = 'Guyana',
  HT = 'Haiti',
  HN = 'Honduras',
  CL = 'Chile',
  HR = 'Chorvatsko',
  IN = 'Indie',
  ID = 'Indonésie',
  IQ = 'Irák',
  IR = 'Írán',
  IE = 'Irsko',
  IS = 'Island',
  IT = 'Itálie',
  IL = 'Izrael',
  JM = 'Jamajka',
  JP = 'Japonsko',
  YE = 'Jemen',
  ZA = 'Jižní Afrika - JAR',
  KR = 'Jižní Korea',
  SS = 'Jižní Súdán',
  JO = 'Jordánsko',
  KH = 'Kambodža',
  CM = 'Kamerun',
  CA = 'Kanada',
  CV = 'Kapverdy',
  QA = 'Katar',
  KZ = 'Kazachstán',
  KE = 'Keňa',
  KI = 'Kiribati',
  CO = 'Kolumbie',
  KM = 'Komory',
  CG = 'Kongo',
  KS = 'Kosovo',
  CR = 'Kostarika',
  CU = 'Kuba',
  KW = 'Kuvajt',
  CY = 'Kypr',
  KG = 'Kyrgyzstán',
  LA = 'Laos',
  LS = 'Lesotho',
  LB = 'Libanon',
  LR = 'Libérie',
  LY = 'Libye',
  LI = 'Lichtenštejnsko',
  LT = 'Litva',
  LV = 'Lotyšsko',
  LU = 'Lucembursko',
  MG = 'Madagaskar',
  HU = 'Maďarsko',
  MK = 'Makedonie',
  MY = 'Malajsie',
  MW = 'Malawi',
  MV = 'Maledivy',
  ML = 'Mali',
  MT = 'Malta',
  MA = 'Maroko',
  MH = 'Marshallovy ostrovy',
  MU = 'Mauricius',
  MR = 'Mauritánie',
  MX = 'Mexiko',
  FM = 'Mikronésie',
  MD = 'Moldavsko',
  MC = 'Monako',
  MN = 'Mongolsko',
  MZ = 'Mosambik',
  NA = 'Namibie',
  NR = 'Nauru',
  DE = 'Německo',
  NP = 'Nepál',
  NE = 'Niger',
  NG = 'Nigérie',
  NI = 'Nikaragua',
  NU = 'Niue',
  NL = 'Nizozemsko',
  NO = 'Norsko',
  NZ = 'Nový Zéland',
  OM = 'Omán',
  PK = 'Pákistán',
  PW = 'Palau',
  PA = 'Panama',
  PG = 'Papua-Nová Guinea',
  PY = 'Paraguay',
  PE = 'Peru',
  CI = 'Pobřeží slonoviny',
  PL = 'Polsko',
  PT = 'Portugalsko',
  AT = 'Rakousko',
  GQ = 'Rovníková Guinea',
  RO = 'Rumunsko',
  RU = 'Rusko',
  RW = 'Rwanda',
  GR = 'Řecko',
  SV = 'Salvador',
  WS = 'Samoa',
  SM = 'San Marino',
  SA = 'Saúdská Arábie',
  SN = 'Senegal',
  KP = 'Severní Korea',
  SC = 'Seychely',
  SL = 'Sierra Leone',
  SG = 'Singapur',
  SK = 'Slovensko',
  SI = 'Slovinsko',
  SO = 'Somálsko',
  AE = 'Spojené arabské emiráty',
  RS = 'Srbsko',
  LK = 'Srí Lanka',
  CF = 'Středoafrická republika',
  SD = 'Súdán',
  SR = 'Surinam',
  LC = 'Svatá Lucie',
  KN = 'Svatý Kryštof',
  ST = 'Svatý Tomáš',
  VC = 'Svatý Vincenc a Grenadiny',
  SZ = 'Svazijsko',
  SY = 'Sýrie',
  SB = 'Šalamounovy ostrovy',
  ES = 'Španělsko',
  SE = 'Švédsko',
  CH = 'Švýcarsko',
  TJ = 'Tádžikistán',
  TZ = 'Tanzanie',
  TH = 'Thajsko',
  TW = 'Tchaj-wan',
  TG = 'Togo',
  TO = 'Tonga',
  TT = 'Trinidad a Tobago',
  TN = 'Tunisko',
  TR = 'Turecko',
  TM = 'Turkmenistán',
  TV = 'Tuvalu',
  UG = 'Uganda',
  UA = 'Ukrajina',
  UY = 'Uruguay',
  US = 'USA',
  UZ = 'Uzbekistán',
  VU = 'Vanuatu',
  VA = 'Vatikán',
  GB = 'Velká Británie',
  VE = 'Venezuela',
  VN = 'Vietnam',
  TL = 'Východní Timor',
  ZM = 'Zambie',
  EH = 'Západní Sahara',
  ZW = 'Zimbabwe'
}
