import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, HostBinding, HostListener, Injector, Input, OnInit, ViewContainerRef } from '@angular/core';
import { NgbTooltip, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { SystemService } from '../services/system.service';
import { TooltipCopyContentComponent } from './components/tooltip-copy-content/tooltip-copy-content.component';
import { Subject, debounceTime, take } from 'rxjs';

@Directive({
  selector: '[appTooltipCopy]',
  standalone: true,
})
export class TooltipCopyDirective implements OnInit {

  @Input({ required: true }) appTooltipCopy: string | undefined;

  @HostBinding('attr.ngbTooltip') tooltip: NgbTooltip = new NgbTooltip();
  copied: boolean = false;
  componentRef: ComponentRef<TooltipCopyContentComponent> | undefined;

  @HostListener('click') onCopy() {
    this.sysService.copyTextToClipboard(this.appTooltipCopy ?? '');
    this.copied = true;
    this.refreshTooltip();
    setTimeout(() => {
      this.copied = false;
      this.removeTooltipContent()
      this.refreshTooltip();

      setTimeout(() => {
        this.closeTooltip();
      });
    }, 1500);
  }
  @HostListener('mouseover') onMouseOver() {
    this.mouseEvents.next('enter');
  }
  @HostListener('mouseleave') onMouseLeave() {
    this.mouseEvents.next('leave');
  }

  private mouseEvents = new Subject<'enter' | 'leave'>();

  constructor(
    private sysService: SystemService,
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private config: NgbTooltipConfig
  ) {
    this.mouseEvents.pipe(
      debounceTime(50) // Adjust time as needed
    ).subscribe(event => {
      if (event === 'enter') {
        this.openTooltip();
      } else {
        this.closeTooltip();
      }
    });
  }

  ngOnInit(): void {
    this.tooltip.ngOnInit();
  }

  setupTooltip() {
    this.tooltip.container ='body';
    this.tooltip.triggers = 'manual';
    
    this.createTooltipContent();
    
    setTimeout(() => {
      this.tooltip.ngbTooltip = this.componentRef?.instance.content ?? null;
    })
  }

  createTooltipContent() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(TooltipCopyContentComponent);
    this.componentRef = factory.create(this.injector);
    this.componentRef.instance.value = this.appTooltipCopy;
    this.componentRef.instance.copied = this.copied;
    this.appRef.attachView(this.componentRef.hostView);
  }
  removeTooltipContent() {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef?.hostView);
      this.componentRef.destroy();
      this.tooltip.ngbTooltip = null;
    }
  }
  

  refreshTooltip() {
    if (!this.tooltip) return;
    this.tooltip.animation = false;
    this.closeTooltip();
    this.openTooltip();
    this.tooltip.animation = true;
  }

  openTooltip() {
    this.setupTooltip();
    
    setTimeout(() => {
      this.tooltip?.open({
        value: this.appTooltipCopy,
        copied: this.copied
      });
    })

  }
  closeTooltip() {
    this.tooltip.close();
  }
}
