import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { ShopItemReservationType } from 'src/app/shared/enums/shop-item/shop-item-reservation-types.enum';
import { IShopItemCreateData } from 'src/app/shared/models/shop-item/shop-item-create-data.model';
import { IShopItemUpdateSectionData } from 'src/app/shared/models/shop-item/shop-item-update-data.model';
import { IShopItem } from 'src/app/shared/models/shop-item/shop-item.model';
import { CallableNames, DbService } from '../../db.service';
import { IFormFieldSettings } from 'src/app/shared/models/shop-item/form-field-settings.model';
import { ICollectedUserData } from 'src/app/shared/models/collecting-data/collected-user-data.model';
import { CollectingFrom } from 'src/app/shared/enums/collecting-data/collecting-from.enum';
import { IShopItemInstallmentsSettings } from 'src/app/shared/models/shop-item/shop-item-installments-settings.model';
import { IEvent } from 'src/app/shared/models/event/event.model';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { ShopItemPublicationType } from 'src/app/shared/enums/shop-item/shop-item-publication-types.enum';
import { IApi } from 'src/app/shared/models/api/api.model';
import { ShopItemSettingModuleType } from 'src/app/shared/models/shop-item/shop-item-setting-module.model';
import { IUpdateSettingModuleData } from 'src/app/shared/models/shop-item/shop-item-setting-module-update-data.model';
import { ShopItemStateChangerResult } from 'src/app/pages/admin/org-admin/events/admin-event/components/shop-item-state-changer/modals/shop-item-state-changer-modal/shop-item-state-changer-modal.component';
import { EventShopItemOrderByType } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-filter/types/event-si-filter-order-by/event-si-filter-order-by.component';
import { IScheduleTaskData } from 'src/app/pages/admin/org-admin/components/task-scheduler-input/task-scheduler-input.component';
import { ShopItemState } from 'src/app/shared/enums/shop-item/shop-item-states.enum';
import { ShopItemReservationState } from 'src/app/shared/enums/shop-item/shop-item-reservation-states.enum';
import { EventShopItemsTableColumnItemType } from 'src/app/pages/admin/org-admin/events/admin-event/components/form-sections/event-shop-items-form-section/event-shop-items-table/event-shop-items-table.component';

interface ICallablesCollectedUserDataUpdateData extends ICollectedUserData {
  organizationId: number;
  oldCollectedUserDataId: number;
}

export interface IShopItemGetData {
  id?: number;
  shopItemUuid?: string;
  eventId?: number;
  eventUuid?: string;
  orgId?: number;

  containArchived?: boolean;
  containOffer?: boolean;

  include?: {
    sessionGroup?: boolean;
    applicableDiscounts?: boolean;
    nextBestOffers?: boolean;
    invoiceProfile?: boolean;
    event?: boolean;
    accreditations?: boolean;
    categories?: boolean;
    scheduledTasks?: boolean;
  }
};

export interface IShopItemGetPresentationData {
  shopItemUuids?: string[];
  eventUuid?: string;
  eventUrlId?: string;
};

export interface IShopItemUpdateStateData {
  shopItemId: number;
  state: ShopItemStateChangerResult;
  sendNotifications?: boolean;
  config?: {
    reservationType?: ShopItemReservationType;
    publicationType?: ShopItemPublicationType;
    scheduledClose: IScheduleTaskData | null;
  }
}

export interface IShopItemGetForAdminEventData {
  eventUuid: string;

  filter: IShopItemGetForAdminEventFiltersData;
};
export interface IShopItemGetForAdminEventFiltersData {
  coordinatorIds?: number[];
  locationIds?: number[]; // -1 === ONLINE
  dateRange?: string[];
  categoryIds?: number[];
  publicTagIds?: number[];
  internalTagIds?: number[];
  orderBy?: EventShopItemOrderByType;
  shopItemStates?: ShopItemState[];
  shopItemReservationStates?: ShopItemReservationState[];
}

export type IShopItemSwitchUiIndexData = {[shopItemId: number]: number};

export interface ICallablesShopItemsFormSettingsUpsertData {
  shopItemId: number;
  formFieldSettings: IFormFieldSettings;
}

export interface ICallablesShopItemsFormSettingsRemoveData {
  shopItemId: number;
  formSettingsFieldId: number;
}

export interface IUnusedCollectedUserData {
  data: ICollectedUserData;
  used: { [key in CollectingFrom]?: boolean; };
}

export interface ICallablesShopItemsFormSettingsUpdateIndicesData {
  shopItemId: number;
  newIdsOrder: number[];
}

export interface ICallablesUpsertInstallmentsSettingsData {
  shopItemId: number;
  settings: IShopItemInstallmentsSettings;
}

export interface ISessionGroupsLecturerGet {
  orgId: number;
}

interface IShopItemGetForReservationShopItemChangeData {
  reservationId: number;
};
export type IShopItemIShopItemGetForReservationShopItemChangeResult = {
  reservation: IReservation;
  candidates: ICandidateForShopItemChange[];
};
export interface ICandidateForShopItemChange {
  shopItem: IShopItem,
  // mappedCustomData: GetCustomDataResultType[],
  // unmappableCustomData: GetCustomDataResultType[],
}

interface IShopItemsGetForReservationsTableItemFilterData {
  orgId: number;
  eventUuid: string | null;
  publishedPublic: boolean;
  publishedPrivate: boolean;
  opened: boolean;
  finished: boolean;
  unpublished: boolean;
  draft: boolean;
}
type IShopItemsGetForReservationsTableItemFilterResult = {
  event: IEvent;
  shopItems: IShopItem[];
}[];

export interface IShopItemsGetPaymentApisResult {
  apis: IApi[];
  selectedApiIds: number[];
}

export interface IShopItemsSetApplicableDiscountsData {
  shopItemId: number;

  activeApplicableDiscountsData: {
    discountTemplateId: number;
  }[]
}

interface ICallablesShopItemsGetShowPreviewData {
  shopItemUuid: string; 
}

export interface ICallablesShopItemsSettingModulesCreateData {
  shopItemId: number;

  type: ShopItemSettingModuleType;
}

export interface ICallablesShopItemsSettingModulesDeleteData {
  shopItemId: number;

  uuid: string;
}

export interface ICallablesShopItemsSettingModulesCloneData {
  originShopItemId: number;
  shopItemIds: number[];
  upsert: boolean;
  moduleType: ShopItemSettingModuleType;

  orgId: number;
  uuid: string;
}

export interface ICallablesShopItemsSettingModulesUpdateData {
  shopItemId: number;
  moduleUuid: string;

  data: IUpdateSettingModuleData;
}

export interface ICallablesShopItemsSettingModulesUpdateUiIndexesData {
  shopItemId: number;

  data: {
    moduleUuid: string;
    uiIndex: number;
  }[];
}

interface ICallablesShopItemsSettingModulesGetAvailableData {
  shopItemId: number;
}

interface IShopItemsGetForDiscountTemplateSpecificShopitemsInputData {
  eventId: number | null;
  orgId: number | null;
};

export interface IShopItemUpdatePublicationTypeData {
  shopItemId: number;
  publicationType: ShopItemPublicationType;
};

export interface IShopItemUpdateReservationTypeData {
  shopItemId: number;
  reservationType: ShopItemReservationType;
};

export interface ICallablesShopItemsCreateInstanceData {
  eventId: number;
  representativeShopItemId: number;
}
interface ICallablesShopItemsCreateInstanceResult {
  shopItemId: number;
  shopItemUuid: string;
  eventUuid: string;
}

interface IShopItemSiAdminStoreGetData {
  orgId: number;
  id?: number;
  shopItemUuid?: string;
  singleSgEventId?: number;
  singleSgEventUuid?: string;
};

export interface ICallablesShopItemsSaveAsTemplateData {
  shopItemId: number;
  withNewCode?: string;
}

export interface ICallablesShopItemsSetAsRepresentativeData {
  shopItemId: number;
  bool: boolean;
}

interface ICallablesShopItemsGetRepresentativesForNewSiModalData {
  organizationId: number;
}

export interface ICallablesShopItemsRemoveInstanceData {
  shopItemId: number;
}

interface ICallablesShopItemsItemNumberGetPreviewData {
  shopItemId: number;
  template: string;
}

interface ICallablesShopItemsItemNumberSaveData {
  shopItemId: number;
  template: string;
}

interface ICallablesShopItemsGetRepresentativeInstanceCountData {
  shopItemId: number;
}

export interface IShopItemDataMap {
  [shopItemId: number]: {
    [key in EventShopItemsTableColumnItemType]?: any;
  }
}

export interface IShopItemGetForAdminEventResult {
  shopItems: IShopItem[];
  dataMap: IShopItemDataMap;
  totalShopItemCount: number
}

interface ICallablesShopItemsSettingModulesCanCloneData {
  moduleType: ShopItemSettingModuleType;
  moduleUuid: string;
  originShopItemId: number;
  shopItemIds: number[];

  orgId: number;
}

interface ICallablesShopItemsSettingModulesCanCloneResult {
  [shopItemId: number]: {
    disabled: boolean;
    tooltip: string | null;
  };
}

@Injectable({
  providedIn: 'root'
})
export class ShopItemsService {

  constructor(
    private dbService: DbService,
  ) { }

  public getRepresentativeInstanceCount(data: ICallablesShopItemsGetRepresentativeInstanceCountData): Observable<number> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsGetRepresentativeInstanceCount, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getRepresentativesForNewSiModal(data: ICallablesShopItemsGetRepresentativesForNewSiModalData): Observable<IShopItem[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsGetRepresentativesForNewSiModal, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public setAsRepresentative(data: ICallablesShopItemsSetAsRepresentativeData): Observable<number> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsSetAsRepresentative, data });
    return this.dbService.handleObs(obs);
  }

  public getForSiAdminStore(data: IShopItemSiAdminStoreGetData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsSiAdminStoreGet, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public updatePublicationType(data: IShopItemUpdatePublicationTypeData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsUpdatePublicationType, data });
    return this.dbService.handleObs(obs);
  }

  public updateReservationType(data: IShopItemUpdateReservationTypeData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsUpdateReservationType, data });
    return this.dbService.handleObs(obs);
  }

  public getForDiscountTemplateSpecificShopitemsInput(data: IShopItemsGetForDiscountTemplateSpecificShopitemsInputData): Observable<IShopItem[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsGetForDiscountTemplateSpecificShopitemsInput, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getAvailableSettingModules(data: ICallablesShopItemsSettingModulesGetAvailableData): Observable<string[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsSettingModulesGetAvailable, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public updateSettingModulesUiIndexes(data: ICallablesShopItemsSettingModulesUpdateUiIndexesData, succSilent: boolean = false) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsSettingModulesUpdateUiIndexes, data });
    return this.dbService.handleObs(obs, { succSilent });
  }

  public updateSettingModules(data: ICallablesShopItemsSettingModulesUpdateData[]) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsSettingModulesUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public createSettingModule(data: ICallablesShopItemsSettingModulesCreateData, succSilent: boolean = false) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsSettingModulesCreate, data });
    return this.dbService.handleObs(obs, { succSilent });
  }

  public deleteSettingModule(data: ICallablesShopItemsSettingModulesDeleteData, succSilent: boolean = false) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsSettingModulesDelete, data });
    return this.dbService.handleObs(obs, { succSilent });
  }

  public cloneSettingModule(data: ICallablesShopItemsSettingModulesCloneData, succSilent: boolean = false) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsSettingModulesClone, data });
    return this.dbService.handleObs(obs, { succSilent });
  }

  public canCloneSettingModule(data: ICallablesShopItemsSettingModulesCanCloneData): Observable<ICallablesShopItemsSettingModulesCanCloneResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsSettingModulesCanClone, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public setShopItemApplicableDiscounts(data: IShopItemsSetApplicableDiscountsData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsSetApplicableDiscounts, data });
    return this.dbService.handleObs(obs);
  }
  
  public getShowPreview(data: ICallablesShopItemsGetShowPreviewData): Observable<boolean> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsGetShowPreview, data });
    return this.dbService.handleObs(obs, { allSilent: true });
  }

  public getShopItemsForReservationsTableItemFilter(data: IShopItemsGetForReservationsTableItemFilterData): Observable<IShopItemsGetForReservationsTableItemFilterResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsGetForReservationsTableItemFilter, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForReservationShopItemChange(data: IShopItemGetForReservationShopItemChangeData): Observable<IShopItemIShopItemGetForReservationShopItemChangeResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsGetForReservationShopItemChange, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public switchUiIndex(data: IShopItemSwitchUiIndexData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsUpdateUiIndex, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getLecturerSessionGroups(data: ISessionGroupsLecturerGet): Observable<IShopItem[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesSessionGroupsLecturerGet, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForAdminEvent(data: IShopItemGetForAdminEventData): Observable<IShopItemGetForAdminEventResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsGetForAdminEventData, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getMany(data: IShopItemGetData): Observable<IShopItem[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsGet, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getSingle(data: IShopItemGetData): Observable<IShopItem> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsGet, data });
    return this.dbService.handleObs(obs, { succSilent: true }).pipe(
      map(x => {
        return x[0] ?? null;
      })
    );
  }

  public create(data: IShopItemCreateData): Observable<IShopItem> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsCreate, data });
    return this.dbService.handleObs(obs);
  }

  public updateBySection(data: IShopItemUpdateSectionData, succSilent: boolean = false): Observable<{ shopItemId: number }> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsUpdateSection, data });
    return this.dbService.handleObs(obs, { succSilent });
  }

  public updateState(data: IShopItemUpdateStateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsUpdateState, data });
    return this.dbService.handleObs(obs.pipe(catchError(e => {
      if (e.details?.eventMissingData) {
        return of({ data: { ...e.details }, noToast: true });
      }
      throw e;
    })));
  }

  public duplicate(shopItemIds: number[]): Observable<IShopItem[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsDuplicate, data: { shopItemIds } });
    return this.dbService.handleObs(obs);
  }

  public archive(shopItemId: number) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsArchive, data: shopItemId });
    return this.dbService.handleObs(obs);
  }
  
  public upsertFormSettings(data: ICallablesShopItemsFormSettingsUpsertData): Observable<IFormFieldSettings> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsFormSettingsUpsert, data: data });
    return this.dbService.handleObs(obs, {});
  }

  public updateFormSettingsIndices(data: ICallablesShopItemsFormSettingsUpdateIndicesData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsFormSettingsUpdateIndices, data: data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public removeFormSettings(data: ICallablesShopItemsFormSettingsRemoveData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsFormSettingsRemove, data: data });
    return this.dbService.handleObs(obs, {});
  }

  public removeCollectedUserData(collectedUserDataId: number) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCollectedUserDataRemove, data: { collectedUserDataId } });
    return this.dbService.handleObs(obs, {});
  }

  public addManyFormSettings(data: { shopItemId: number; formFieldSettings: IFormFieldSettings[]; }): Observable<IFormFieldSettings[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsFormSettingsAddMany, data: data });
    return this.dbService.handleObs(obs, {});
  }

  public updateCollectedUserDataField(data: ICallablesCollectedUserDataUpdateData): Observable<ICollectedUserData> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCollectedUserDataUpdate, data: data });
    return this.dbService.handleObs(obs, {});
  }

  public upsertInstallmentsSettings(data: ICallablesUpsertInstallmentsSettingsData): Observable<IShopItem> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsUpsertInstallmentsSettings, data: data });
    return this.dbService.handleObs(obs, {});
  }
  
  // TODO: move elsewhere?
  public getUnusedCollectedUserData(shopItemId: number): Observable<IUnusedCollectedUserData[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesCollectedUserDataGetUnused, data: { shopItemId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getPaymentApis(shopItemId: number): Observable<IShopItemsGetPaymentApisResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsGetPaymentApis, data: { shopItemId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getTemplates(organizationId: number): Observable<IShopItem[]> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsGetTemplates, data: { organizationId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public hasTemplates(organizationId: number): Observable<boolean> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsHasTemplates, data: { organizationId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public saveAsTemplate(data: ICallablesShopItemsSaveAsTemplateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsSaveAsTemplate, data });
    return this.dbService.handleObs(obs);
  }

  public createInstance(data: ICallablesShopItemsCreateInstanceData): Observable<ICallablesShopItemsCreateInstanceResult> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsCreateInstance, data });
    return this.dbService.handleObs(obs);
  }

  public removeInstance(data: ICallablesShopItemsRemoveInstanceData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsRemoveInstance, data });
    return this.dbService.handleObs(obs);
  }

  public getItemNumberPreview(data: ICallablesShopItemsItemNumberGetPreviewData): Observable<{ customItemNumber: string }> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsItemNumberGetPreview, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public saveItemNumber(data: ICallablesShopItemsItemNumberSaveData): Observable<IShopItem> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsItemNumberSave, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public hasReservations(shopItemId: number): Observable<boolean> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesShopItemsHasReservations, data: { shopItemId } });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
