import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal } from 'src/app/shared/modals/modal';
import { ModalHeaderComponent } from 'src/app/shared/modals/components/modal-header/modal-header.component';
import { ButtonModule } from 'primeng/button';
import { DateInputComponent } from 'src/app/shared/components/date-input/date-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';

export type IOrderExpirationDatesModalResult = {
  newMaturity: string,
  newExpiration: string,
} | null;

@Component({
  selector: 'app-order-expiration-dates-modal',
  standalone: true,
  imports: [
    CommonModule, TranslateModule, FormsModule,
    ButtonModule, CalendarModule,
    ModalHeaderComponent, DateInputComponent,
  ],
  templateUrl: './order-expiration-dates-modal.component.html',
  styleUrls: ['./order-expiration-dates-modal.component.scss']
})
export class OrderExpirationDatesModalComponent extends Modal {

  @Input() maturity: string | undefined;
  @Input() expiration: string | undefined;
  @Output() choosenDates = new EventEmitter<IOrderExpirationDatesModalResult>();

  maturityDate: Date | null = null;
  expirationDate: Date | null = null;

  minMaturityValue: Date = new Date();
  minExpirationValue: Date = new Date();


  ngOnInit() {
    this.maturityDate = new Date(this.maturity ?? new Date());
    this.expirationDate = new Date(this.expiration ?? new Date());
  }

  onSubmit() {
    if (!this.expirationDate || !this.maturityDate) {
      this.choosenDates.emit(null);
    } else {
      this.choosenDates.emit({
        newExpiration: this.expirationDate?.toISOString(),
        newMaturity: this.maturityDate?.toISOString()
      });
    }
    this.close();
  }
}
