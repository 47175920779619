

import { animate, AUTO_STYLE, state, style, transition, trigger } from "@angular/animations";


const COLLAPSE_ANIMATION_DURATION = 200;

export const collapseAnimation =
  trigger('collapse', [
    state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })),
    state('true', style({ height: '0', visibility: 'hidden', opacity: 0, 'overflow-y': 'hidden' })),
    transition('false => true', animate(COLLAPSE_ANIMATION_DURATION + 'ms ease-in')),
    transition('true => false', animate((COLLAPSE_ANIMATION_DURATION * (4/5)) + 'ms ease-out'))
  ]);
