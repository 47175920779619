export interface ICountry {
  name: string;
  dialCode: string;
  emoji: string;
  code: string;
  phoneLength?: number;
}

export const Countries: ICountry[] = [{
  'name': 'Afghanistan',
  'dialCode': '+93',
  'emoji': '🇦🇫',
  'code': 'AF',
  'phoneLength': 9
},
{
  'name': 'Aland Islands',
  'dialCode': '+358',
  'emoji': '🇦🇽',
  'code': 'AX',
  'phoneLength': 7
},
{
  'name': 'Albania',
  'dialCode': '+355',
  'emoji': '🇦🇱',
  'code': 'AL',
  'phoneLength': 9
},
{
  'name': 'Algeria',
  'dialCode': '+213',
  'emoji': '🇩🇿',
  'code': 'DZ',
  'phoneLength': 9
},
{
  'name': 'AmericanSamoa',
  'dialCode': '+1684',
  'emoji': '🇦🇸',
  'code': 'AS',
  'phoneLength': 10
},
{
  'name': 'Andorra',
  'dialCode': '+376',
  'emoji': '🇦🇩',
  'code': 'AD',
  'phoneLength': 6
},
{
  'name': 'Angola',
  'dialCode': '+244',
  'emoji': '🇦🇴',
  'code': 'AO',
  'phoneLength': 9
},
{
  'name': 'Anguilla',
  'dialCode': '+1264',
  'emoji': '🇦🇮',
  'code': 'AI',
  'phoneLength': 10
},
{
  'name': 'Antarctica',
  'dialCode': '+672',
  'emoji': '🇦🇶',
  'code': 'AQ',
  'phoneLength': 6
},
{
  'name': 'Antigua and Barbuda',
  'dialCode': '+1268',
  'emoji': '🇦🇬',
  'code': 'AG',
  'phoneLength': 10
},
{
  'name': 'Argentina',
  'dialCode': '+54',
  'emoji': '🇦🇷',
  'code': 'AR',
  'phoneLength': 10
},
{
  'name': 'Armenia',
  'dialCode': '+374',
  'emoji': '🇦🇲',
  'code': 'AM',
  'phoneLength': 6
},
{
  'name': 'Aruba',
  'dialCode': '+297',
  'emoji': '🇦🇼',
  'code': 'AW',
  'phoneLength': 7
},
{
  'name': 'Australia',
  'dialCode': '+61',
  'emoji': '🇦🇺',
  'code': 'AU',
  'phoneLength': 9
},
{
  'name': 'Austria',
  'dialCode': '+43',
  'emoji': '🇦🇹',
  'code': 'AT',
  'phoneLength': 10
},
{
  'name': 'Azerbaijan',
  'dialCode': '+994',
  'emoji': '🇦🇿',
  'code': 'AZ',
  'phoneLength': 9
},
{
  'name': 'Bahamas',
  'dialCode': '+1242',
  'emoji': '🇧🇸',
  'code': 'BS',
  'phoneLength': 10
},
{
  'name': 'Bahrain',
  'dialCode': '+973',
  'emoji': '🇧🇭',
  'code': 'BH',
  'phoneLength': 8
},
{
  'name': 'Bangladesh',
  'dialCode': '+880',
  'emoji': '🇧🇩',
  'code': 'BD',
  'phoneLength': 10
},
{
  'name': 'Barbados',
  'dialCode': '+1246',
  'emoji': '🇧🇧',
  'code': 'BB',
  'phoneLength': 10
},
{
  'name': 'Belarus',
  'dialCode': '+375',
  'emoji': '🇧🇾',
  'code': 'BY',
  'phoneLength': 9
},
{
  'name': 'Belgium',
  'dialCode': '+32',
  'emoji': '🇧🇪',
  'code': 'BE',
  'phoneLength': 9
},
{
  'name': 'Belize',
  'dialCode': '+501',
  'emoji': '🇧🇿',
  'code': 'BZ',
  'phoneLength': 7
},
{
  'name': 'Benin',
  'dialCode': '+229',
  'emoji': '🇧🇯',
  'code': 'BJ',
  'phoneLength': 8
},
{
  'name': 'Bermuda',
  'dialCode': '+1441',
  'emoji': '🇧🇲',
  'code': 'BM',
  'phoneLength': 10
},
{
  'name': 'Bhutan',
  'dialCode': '+975',
  'emoji': '🇧🇹',
  'code': 'BT',
  'phoneLength': 7
},
{
  'name': 'Bolivia, Plurinational State of',
  'dialCode': '+591',
  'emoji': '🇧🇴',
  'code': 'BO',
  'phoneLength': 9
},
{
  'name': 'Bosnia and Herzegovina',
  'dialCode': '+387',
  'emoji': '🇧🇦',
  'code': 'BA',
  'phoneLength': 8
},
{
  'name': 'Botswana',
  'dialCode': '+267',
  'emoji': '🇧🇼',
  'code': 'BW',
  'phoneLength': 7
},
{
  'name': 'Brazil',
  'dialCode': '+55',
  'emoji': '🇧🇷',
  'code': 'BR',
  'phoneLength': 11
},
{
  'name': 'British Indian Ocean Territory',
  'dialCode': '+246',
  'emoji': '🇮🇴',
  'code': 'IO',
  'phoneLength': 7
},
{
  'name': 'Brunei Darussalam',
  'dialCode': '+673',
  'emoji': '🇧🇳',
  'code': 'BN',
  'phoneLength': 7
},
{
  'name': 'Bulgaria',
  'dialCode': '+359',
  'emoji': '🇧🇬',
  'code': 'BG',
  'phoneLength': 9
},
{
  'name': 'Burkina Faso',
  'dialCode': '+226',
  'emoji': '🇧🇫',
  'code': 'BF',
  'phoneLength': 8
},
{
  'name': 'Burundi',
  'dialCode': '+257',
  'emoji': '🇧🇮',
  'code': 'BI',
  'phoneLength': 8
},
{
  'name': 'Cambodia',
  'dialCode': '+855',
  'emoji': '🇰🇭',
  'code': 'KH',
  'phoneLength': 9
},
{
  'name': 'Cameroon',
  'dialCode': '+237',
  'emoji': '🇨🇲',
  'code': 'CM',
  'phoneLength': 9
},
{
  'name': 'Canada',
  'dialCode': '+1',
  'emoji': '🇨🇦',
  'code': 'CA',
  'phoneLength': 10
},
{
  'name': 'Cape Verde',
  'dialCode': '+238',
  'emoji': '🇨🇻',
  'code': 'CV',
  'phoneLength': 7
},
{
  'name': 'Cayman Islands',
  'dialCode': '+345',
  'emoji': '🇰🇾',
  'code': 'KY',
  'phoneLength': 7
},
{
  'name': 'Central African Republic',
  'dialCode': '+236',
  'emoji': '🇨🇫',
  'code': 'CF',
  'phoneLength': 8
},
{
  'name': 'Chad',
  'dialCode': '+235',
  'emoji': '🇹🇩',
  'code': 'TD',
  'phoneLength': 6
},
{
  'name': 'Chile',
  'dialCode': '+56',
  'emoji': '🇨🇱',
  'code': 'CL',
  'phoneLength': 9
},
{
  'name': 'China',
  'dialCode': '+86',
  'emoji': '🇨🇳',
  'code': 'CN',
  'phoneLength': 11
},
{
  'name': 'Christmas Island',
  'dialCode': '+61',
  'emoji': '🇨🇽',
  'code': 'CX',
  'phoneLength': 9
},
{
  'name': 'Cocos (Keeling) Islands',
  'dialCode': '+61',
  'emoji': '🇨🇨',
  'code': 'CC',
  'phoneLength': 10
},
{
  'name': 'Colombia',
  'dialCode': '+57',
  'emoji': '🇨🇴',
  'code': 'CO',
  'phoneLength': 10
},
{
  'name': 'Comoros',
  'dialCode': '+269',
  'emoji': '🇰🇲',
  'code': 'KM',
  'phoneLength': 7
},
{
  'name': 'Congo',
  'dialCode': '+242',
  'emoji': '🇨🇬',
  'code': 'CG',
  'phoneLength': 9
},
{
  'name': 'Congo, The Democratic Republic of the Congo',
  'dialCode': '+243',
  'emoji': '🇨🇩',
  'code': 'CD',
  'phoneLength': 7
},
{
  'name': 'Cook Islands',
  'dialCode': '+682',
  'emoji': '🇨🇰',
  'code': 'CK',
  'phoneLength': 5
},
{
  'name': 'Costa Rica',
  'dialCode': '+506',
  'emoji': '🇨🇷',
  'code': 'CR',
  'phoneLength': 8
},
{
  'name': "Cote d'Ivoire",
  'dialCode': '+225',
  'emoji': '🇨🇮',
  'code': 'CI',
  'phoneLength': 8
},
{
  'name': 'Croatia',
  'dialCode': '+385',
  'emoji': '🇭🇷',
  'code': 'HR',
  'phoneLength': 9
},
{
  'name': 'Cuba',
  'dialCode': '+53',
  'emoji': '🇨🇺',
  'code': 'CU',
  'phoneLength': 8
},
{
  'name': 'Cyprus',
  'dialCode': '+357',
  'emoji': '🇨🇾',
  'code': 'CY',
  'phoneLength': 8
},
{
  'name': 'Czech Republic',
  'dialCode': '+420',
  'emoji': '🇨🇿',
  'code': 'CZ',
  'phoneLength': 9
},
{
  'name': 'Denmark',
  'dialCode': '+45',
  'emoji': '🇩🇰',
  'code': 'DK',
  'phoneLength': 8
},
{
  'name': 'Djibouti',
  'dialCode': '+253',
  'emoji': '🇩🇯',
  'code': 'DJ',
  'phoneLength': 10
},
{
  'name': 'Dominica',
  'dialCode': '+1767',
  'emoji': '🇩🇲',
  'code': 'DM',
  'phoneLength': 10
},
{
  'name': 'Dominican Republic',
  'dialCode': '+1849',
  'emoji': '🇩🇴',
  'code': 'DO',
  'phoneLength': 10
},
{
  'name': 'Ecuador',
  'dialCode': '+593',
  'emoji': '🇪🇨',
  'code': 'EC',
  'phoneLength': 9
},
{
  'name': 'Egypt',
  'dialCode': '+20',
  'emoji': '🇪🇬',
  'code': 'EG',
  'phoneLength': 10
},
{
  'name': 'El Salvador',
  'dialCode': '+503',
  'emoji': '🇸🇻',
  'code': 'SV',
  'phoneLength': 8
},
{
  'name': 'Equatorial Guinea',
  'dialCode': '+240',
  'emoji': '🇬🇶',
  'code': 'GQ',
  'phoneLength': 9
},
{
  'name': 'Eritrea',
  'dialCode': '+291',
  'emoji': '🇪🇷',
  'code': 'ER',
  'phoneLength': 7
},
{
  'name': 'Estonia',
  'dialCode': '+372',
  'emoji': '🇪🇪',
  'code': 'EE',
  'phoneLength': 8
},
{
  'name': 'Ethiopia',
  'dialCode': '+251',
  'emoji': '🇪🇹',
  'code': 'ET',
  'phoneLength': 9
},
{
  'name': 'Falkland Islands (Malvinas)',
  'dialCode': '+500',
  'emoji': '🇫🇰',
  'code': 'FK',
  'phoneLength': 5
},
{
  'name': 'Faroe Islands',
  'dialCode': '+298',
  'emoji': '🇫🇴',
  'code': 'FO',
  'phoneLength': 5
},
{
  'name': 'Fiji',
  'dialCode': '+679',
  'emoji': '🇫🇯',
  'code': 'FJ',
  'phoneLength': 7
},
{
  'name': 'Finland',
  'dialCode': '+358',
  'emoji': '🇫🇮',
  'code': 'FI',
  'phoneLength': 7
},
{
  'name': 'France',
  'dialCode': '+33',
  'emoji': '🇫🇷',
  'code': 'FR',
  'phoneLength': 9
},
{
  'name': 'French Guiana',
  'dialCode': '+594',
  'emoji': '🇬🇫',
  'code': 'GF',
  'phoneLength': 9
},
{
  'name': 'French Polynesia',
  'dialCode': '+689',
  'emoji': '🇵🇫',
  'code': 'PF',
  'phoneLength': 8
},
{
  'name': 'Gabon',
  'dialCode': '+241',
  'emoji': '🇬🇦',
  'code': 'GA',
  'phoneLength': 7
},
{
  'name': 'Gambia',
  'dialCode': '+220',
  'emoji': '🇬🇲',
  'code': 'GM',
  'phoneLength': 7
},
{
  'name': 'Georgia',
  'dialCode': '+995',
  'emoji': '🇬🇪',
  'code': 'GE',
  'phoneLength': 9
},
{
  'name': 'Germany',
  'dialCode': '+49',
  'emoji': '🇩🇪',
  'code': 'DE',
  'phoneLength': 10
},
{
  'name': 'Ghana',
  'dialCode': '+233',
  'emoji': '🇬🇭',
  'code': 'GH',
  'phoneLength': 9
},
{
  'name': 'Gibraltar',
  'dialCode': '+350',
  'emoji': '🇬🇮',
  'code': 'GI',
  'phoneLength': 8
},
{
  'name': 'Greece',
  'dialCode': '+30',
  'emoji': '🇬🇷',
  'code': 'GR',
  'phoneLength': 10
},
{
  'name': 'Greenland',
  'dialCode': '+299',
  'emoji': '🇬🇱',
  'code': 'GL',
  'phoneLength': 6
},
{
  'name': 'Grenada',
  'dialCode': '+1473',
  'emoji': '🇬🇩',
  'code': 'GD',
  'phoneLength': 10
},
{
  'name': 'Guadeloupe',
  'dialCode': '+590',
  'emoji': '🇬🇵',
  'code': 'GP',
  'phoneLength': 9
},
{
  'name': 'Guam',
  'dialCode': '+1671',
  'emoji': '🇬🇺',
  'code': 'GU',
  'phoneLength': 10
},
{
  'name': 'Guatemala',
  'dialCode': '+502',
  'emoji': '🇬🇹',
  'code': 'GT',
  'phoneLength': 8
},
{
  'name': 'Guernsey',
  'dialCode': '+44',
  'emoji': '🇬🇬',
  'code': 'GG',
  'phoneLength': 10
},
{
  'name': 'Guinea',
  'dialCode': '+224',
  'emoji': '🇬🇳',
  'code': 'GN',
  'phoneLength': 9
},
{
  'name': 'Guinea-Bissau',
  'dialCode': '+245',
  'emoji': '🇬🇼',
  'code': 'GW',
  'phoneLength': 9
},
{
  'name': 'Guyana',
  'dialCode': '+595',
  'emoji': '🇬🇾',
  'code': 'GY',
  'phoneLength': 7
},
{
  'name': 'Haiti',
  'dialCode': '+509',
  'emoji': '🇭🇹',
  'code': 'HT',
  'phoneLength': 8
},
{
  'name': 'Holy See (Vatican City State)',
  'dialCode': '+379',
  'emoji': '🇻🇦',
  'code': 'VA',
  'phoneLength': 10
},
{
  'name': 'Honduras',
  'dialCode': '+504',
  'emoji': '🇭🇳',
  'code': 'HN',
  'phoneLength': 8
},
{
  'name': 'Hong Kong',
  'dialCode': '+852',
  'emoji': '🇭🇰',
  'code': 'HK',
  'phoneLength': 8
},
{
  'name': 'Hungary',
  'dialCode': '+36',
  'emoji': '🇭🇺',
  'code': 'HU',
  'phoneLength': 9
},
{
  'name': 'Iceland',
  'dialCode': '+354',
  'emoji': '🇮🇸',
  'code': 'IS',
  'phoneLength': 7
},
{
  'name': 'India',
  'dialCode': '+91',
  'emoji': '🇮🇳',
  'code': 'IN',
  'phoneLength': 10
},
{
  'name': 'Indonesia',
  'dialCode': '+62',
  'emoji': '🇮🇩',
  'code': 'ID',
  'phoneLength': 11
},
{
  'name': 'Iran, Islamic Republic of Persian Gulf',
  'dialCode': '+98',
  'emoji': '🇮🇷',
  'code': 'IR',
  'phoneLength': 11
},
{
  'name': 'Iraq',
  'dialCode': '+964',
  'emoji': '🇮🇷',
  'code': 'IQ',
  'phoneLength': 10
},
{
  'name': 'Ireland',
  'dialCode': '+353',
  'emoji': '🇮🇪',
  'code': 'IE',
  'phoneLength': 9
},
{
  'name': 'Isle of Man',
  'dialCode': '+44',
  'emoji': '🇮🇲',
  'code': 'IM',
  'phoneLength': 10
},
{
  'name': 'Israel',
  'dialCode': '+972',
  'emoji': '🇮🇱',
  'code': 'IL',
  'phoneLength': 9
},
{
  'name': 'Italy',
  'dialCode': '+39',
  'emoji': '🇮🇹',
  'code': 'IT',
  'phoneLength': 10
},
{
  'name': 'Jamaica',
  'dialCode': '+1876',
  'emoji': '🇯🇲',
  'code': 'JM',
  'phoneLength': 10
},
{
  'name': 'Japan',
  'dialCode': '+81',
  'emoji': '🇯🇵',
  'code': 'JP',
  'phoneLength': 10
},
{
  'name': 'Jersey',
  'dialCode': '+44',
  'emoji': '🇯🇪',
  'code': 'JE',
  'phoneLength': 10
},
{
  'name':
  'Jordan',
  'dialCode': '+962',
  'emoji': '🇯🇴',
  'code': 'JO',
  'phoneLength': 9
},
{
  'name': 'Kazakhstan',
  'dialCode': '+77',
  'emoji': '🇰🇿',
  'code': 'KZ',
  'phoneLength': 10
},
{
  'name': 'Kenya',
  'dialCode': '+254',
  'emoji': '🇰🇪',
  'code': 'KE',
  'phoneLength': 10
},
{
  'name': 'Kiribati',
  'dialCode': '+686',
  'emoji': '🇰🇮',
  'code': 'KI',
  'phoneLength': 8
},
{
  'name': "Korea, Democratic People's Republic of Korea",
  'dialCode': '+850',
  'emoji': '🇰🇵',
  'code': 'KP'
},
{
  'name': 'Korea, Republic of South Korea',
  'dialCode': '+82',
  'emoji': '🇰🇷',
  'code': 'KR'
},
{
  'name': 'Kuwait',
  'dialCode': '+965',
  'emoji': '🇰🇼',
  'code': 'KW',
  'phoneLength': 8
},
{
  'name': 'Kyrgyzstan',
  'dialCode': '+996',
  'emoji': '🇰🇬',
  'code': 'KG',
  'phoneLength': 9
},
{
  'name': 'Laos',
  'dialCode': '+856',
  'emoji': '🇱🇦',
  'code': 'LA',
  'phoneLength': 8
},
{
  'name': 'Latvia',
  'dialCode': '+371',
  'emoji': '🇱🇻',
  'code': 'LV',
  'phoneLength': 8
},
{
  'name': 'Lebanon',
  'dialCode': '+961',
  'emoji': '🇱🇧',
  'code': 'LB',
  'phoneLength': 7
},
{
  'name': 'Lesotho',
  'dialCode': '+266',
  'emoji': '🇱🇸',
  'code': 'LS',
  'phoneLength': 8
},
{
  'name': 'Liberia',
  'dialCode': '+231',
  'emoji': '🇱🇷',
  'code': 'LR',
  'phoneLength': 7
},
{
  'name': 'Libyan Arab Jamahiriya',
  'dialCode': '+218',
  'emoji': '🇱🇾',
  'code': 'LY',
  'phoneLength': 10
},
{
  'name': 'Liechtenstein',
  'dialCode': '+423',
  'emoji': '🇱🇮',
  'code': 'LI',
  'phoneLength': 7
},
{
  'name': 'Lithuania',
  'dialCode': '+370',
  'emoji': '🇱🇹',
  'code': 'LT',
  'phoneLength': 8
},
{
  'name': 'Luxembourg',
  'dialCode': '+352',
  'emoji': '🇱🇺',
  'code': 'LU',
  'phoneLength': 9
},
{
  'name': 'Macao',
  'dialCode': '+853',
  'emoji': '🇲🇴',
  'code': 'MO',
  'phoneLength': 8
},
{
  'name': 'Macedonia',
  'dialCode': '+389',
  'emoji': '🇲🇰',
  'code': 'MK',
  'phoneLength': 8
},
{
  'name': 'Madagascar',
  'dialCode': '+261',
  'emoji': '🇲🇬',
  'code': 'MG',
  'phoneLength': 7
},
{ 'name': 'Malawi', 'dialCode': '+265', 'emoji': '🇲🇼', 'code': 'MW' },
{
  'name': 'Malaysia',
  'dialCode': '+60',
  'emoji': '🇲🇾',
  'code': 'MY',
  'phoneLength': 7
},
{
  'name': 'Maldives',
  'dialCode': '+960',
  'emoji': '🇲🇻',
  'code': 'MV',
  'phoneLength': 7
},
{
  'name': 'Mali',
  'dialCode': '+223',
  'emoji': '🇲🇱',
  'code': 'ML',
  'phoneLength': 8
},
{
  'name': 'Malta',
  'dialCode': '+356',
  'emoji': '🇲🇹',
  'code': 'MT',
  'phoneLength': 8
},
{
  'name': 'Marshall Islands',
  'dialCode': '+692',
  'emoji': '🇲🇭',
  'code': 'MH',
  'phoneLength': 7
},
{
  'name': 'Martinique',
  'dialCode': '+596',
  'emoji': '🇲🇶',
  'code': 'MQ',
  'phoneLength': 9
},
{
  'name': 'Mauritania',
  'dialCode': '+222',
  'emoji': '🇲🇷',
  'code': 'MR',
  'phoneLength': 8
},
{
  'name': 'Mauritius',
  'dialCode': '+230',
  'emoji': '🇲🇺',
  'code': 'MU',
  'phoneLength': 8
},
{
  'name': 'Mayotte',
  'dialCode': '+262',
  'emoji': '🇾🇹',
  'code': 'YT',
  'phoneLength': 9
},
{
  'name': 'Mexico',
  'dialCode': '+52',
  'emoji': '🇲🇽',
  'code': 'MX',
  'phoneLength': 10
},
{
  'name': 'Micronesia, Federated States of Micronesia',
  'dialCode': '+691',
  'emoji': '🇫🇲',
  'code': 'FM',
  'phoneLength': 7
},
{
  'name': 'Moldova',
  'dialCode': '+373',
  'emoji': '🇲🇩',
  'code': 'MD',
  'phoneLength': 8
},
{
  'name': 'Monaco',
  'dialCode': '+377',
  'emoji': '🇲🇨',
  'code': 'MC',
  'phoneLength': 8
},
{
  'name': 'Mongolia',
  'dialCode': '+976',
  'emoji': '🇲🇳',
  'code': 'MN',
  'phoneLength': 8
},
{
  'name': 'Montenegro',
  'dialCode': '+382',
  'emoji': '🇲🇪',
  'code': 'ME',
  'phoneLength': 8
},
{
  'name': 'Montserrat',
  'dialCode': '+1664',
  'emoji': '🇲🇸',
  'code': 'MS',
  'phoneLength': 10
},
{
  'name': 'Morocco',
  'dialCode': '+212',
  'emoji': '🇲🇦',
  'code': 'MA',
  'phoneLength': 9
},
{
  'name': 'Mozambique',
  'dialCode': '+258',
  'emoji': '🇲🇿',
  'code': 'MZ',
  'phoneLength': 12
},
{
  'name': 'Myanmar',
  'dialCode': '+95',
  'emoji': '🇲🇲',
  'code': 'MM',
  'phoneLength': 8
},
{
  'name': 'Namibia',
  'emoji': '🇳🇦',
  'dialCode': '+264',
  'code': 'NA',
  'phoneLength': 7
},
{
  'name': 'Nauru',
  'dialCode': '+674',
  'emoji': '🇳🇷',
  'code': 'NR',
  'phoneLength': 7
},
{
  'name': 'Nepal',
  'dialCode': '+977',
  'emoji': '🇳🇵',
  'code': 'NP',
  'phoneLength': 10
},
{
  'name': 'Netherlands',
  'dialCode': '+31',
  'emoji': '🇳🇱',
  'code': 'NL',
  'phoneLength': 9
},
{
  'name': 'Netherlands Antilles',
  'dialCode': '+599',
  'emoji': '🇧🇶',
  'code': 'AN'
},
{
  'name': 'New Caledonia',
  'dialCode': '+687',
  'emoji': '🇳🇨',
  'code': 'NC',
  'phoneLength': 6
},
{
  'name': 'New Zealand',
  'dialCode': '+64',
  'emoji': '🇳🇿',
  'code': 'NZ',
  'phoneLength': 9
},
{
  'name': 'Nicaragua',
  'dialCode': '+505',
  'emoji': '🇳🇮',
  'code': 'NI',
  'phoneLength': 8
},
{
  'name': 'Niger',
  'dialCode': '+227',
  'emoji': '🇳🇪',
  'code': 'NE',
  'phoneLength': 8
},
{
  'name': 'Nigeria',
  'dialCode': '+234',
  'emoji': '🇳🇬',
  'code': 'NG',
  'phoneLength': 8
},
{
  'name': 'Niue',
  'dialCode': '+683',
  'emoji': '🇳🇺',
  'code': 'NU',
  'phoneLength': 4
},
{
  'name': 'Norfolk Island',
  'dialCode': '+672',
  'emoji': '🇳🇫',
  'code': 'NF',
  'phoneLength': 6
},
{
  'name': 'Northern Mariana Islands',
  'dialCode': '+1670',
  'emoji': '🇲🇵',
  'code': 'MP',
  'phoneLength': 7
},
{
  'name': 'Norway',
  'dialCode': '+47',
  'emoji': '🇳🇴',
  'code': 'NO',
  'phoneLength': 8
},
{
  'name': 'Oman',
  'dialCode': '+968',
  'emoji': '🇴🇲',
  'code': 'OM',
  'phoneLength': 8
},
{
  'name': 'Pakistan',
  'dialCode': '+92',
  'emoji': '🇵🇰',
  'code': 'PK',
  'phoneLength': 10
},
{
  'name': 'Palau',
  'dialCode': '+680',
  'emoji': '🇵🇼',
  'code': 'PW',
  'phoneLength': 7
},
{
  'name': 'Palestinian Territory, Occupied',
  'dialCode': '+970',
  'emoji': '🇵🇸',
  'code': 'PS',
  'phoneLength': 9
},
{
  'name': 'Panama',
  'dialCode': '+507',
  'emoji': '🇵🇦',
  'code': 'PA',
  'phoneLength': 8
},
{
  'name': 'Papua New Guinea',
  'dialCode': '+675',
  'emoji': '🇵🇬',
  'code': 'PG',
  'phoneLength': 8
},
{
  'name': 'Paraguay',
  'dialCode': '+595',
  'emoji': '🇵🇾',
  'code': 'PY',
  'phoneLength': 9
},
{
  'name': 'Peru',
  'dialCode': '+51',
  'emoji': '🇵🇪',
  'code': 'PE',
  'phoneLength': 9
},
{
  'name': 'Philippines',
  'dialCode': '+63',
  'emoji': '🇵🇭',
  'code': 'PH',
  'phoneLength': 10
},
{
  'name': 'Pitcairn',
  'dialCode': '+872',
  'emoji': '🇵🇳',
  'code': 'PN',
  'phoneLength': 9
},
{
  'name': 'Poland',
  'dialCode': '+48',
  'emoji': '🇵🇱',
  'code': 'PL',
  'phoneLength': 9
},
{
  'name': 'Portugal',
  'dialCode': '+351',
  'emoji': '🇵🇹',
  'code': 'PT',
  'phoneLength': 9
},
{
  'name': 'Puerto Rico',
  'dialCode': '+1939',
  'emoji': '🇵🇷',
  'code': 'PR',
  'phoneLength': 10
},
{
  'name': 'Qatar',
  'dialCode': '+974',
  'emoji': '🇶🇦',
  'code': 'QA',
  'phoneLength': 8
},
{
  'name': 'Romania',
  'dialCode': '+40',
  'emoji': '🇷🇴',
  'code': 'RO',
  'phoneLength': 10
},
{
  'name': 'Russia',
  'dialCode': '+7',
  'emoji': '🇷🇺',
  'code': 'RU',
  'phoneLength': 10
},
{
  'name': 'Rwanda',
  'dialCode': '+250',
  'emoji': '🇷🇼',
  'code': 'RW',
  'phoneLength': 9
},
{
  'name': 'Reunion',
  'dialCode': '+262',
  'emoji': '🇷🇪',
  'code': 'RE',
  'phoneLength': 10
},
{
  'name': 'Saint Barthelemy',
  'dialCode': '+590',
  'emoji': '🇧🇱',
  'code': 'BL',
  'phoneLength': 9
},
{
  'name': 'Saint Helena, Ascension and Tristan Da Cunha',
  'dialCode': '+290',
  'emoji': '🇸🇭',
  'code': 'SH',
  'phoneLength': 4
},
{
  'name': 'Saint Kitts and Nevis',
  'dialCode': '+1869',
  'emoji': '🇰🇳',
  'code': 'KN',
  'phoneLength': 10
},
{
  'name': 'Saint Lucia',
  'dialCode': '+1758',
  'emoji': '🇱🇨',
  'code': 'LC',
  'phoneLength': 7
},
{
  'name': 'Saint Martin',
  'dialCode': '+590',
  'emoji': '🇲🇫',
  'code': 'MF',
  'phoneLength': 6
},
{
  'name': 'Saint Pierre and Miquelon',
  'dialCode': '+508',
  'emoji': '🇵🇲',
  'code': 'PM',
  'phoneLength': 6
},
{
  'name': 'Saint Vincent and the Grenadines',
  'dialCode': '+1784',
  'emoji': '🇻🇨',
  'code': 'VC',
  'phoneLength': 7
},
{
  'name': 'Samoa',
  'dialCode': '+685',
  'emoji': '🇼🇸',
  'code': 'WS',
  'phoneLength': 5
},
{
  'name': 'San Marino',
  'dialCode': '+378',
  'emoji': '🇸🇲',
  'code': 'SM',
  'phoneLength': 10
},
{
  'name': 'Sao Tome and Principe',
  'dialCode': '+239',
  'emoji': '🇸🇹',
  'code': 'ST',
  'phoneLength': 7
},
{
  'name': 'Saudi Arabia',
  'dialCode': '+966',
  'emoji': '🇸🇦',
  'code': 'SA',
  'phoneLength': 9
},
{
  'name': 'Senegal',
  'dialCode': '+221',
  'emoji': '🇸🇳',
  'code': 'SN',
  'phoneLength': 9
},
{
  'name': 'Serbia',
  'dialCode': '+381',
  'emoji': '🇷🇸',
  'code': 'RS',
  'phoneLength': 9
},
{
  'name': 'Seychelles',
  'dialCode': '+248',
  'emoji': '🇸🇨',
  'code': 'SC',
  'phoneLength': 7
},
{
  'name': 'Sierra Leone',
  'dialCode': '+232',
  'emoji': '🇸🇱',
  'code': 'SL',
  'phoneLength': 8
},
{
  'name': 'Singapore',
  'dialCode': '+65',
  'emoji': '🇸🇬',
  'code': 'SG',
  'phoneLength': 8
},
{
  'name': 'Slovakia',
  'dialCode': '+421',
  'emoji': '🇸🇰',
  'code': 'SK',
  'phoneLength': 9
},
{
  'name': 'Slovenia',
  'dialCode': '+386',
  'emoji': '🇸🇮',
  'code': 'SI',
  'phoneLength': 9
},
{
  'name': 'Solomon Islands',
  'dialCode': '+677',
  'emoji': '🇸🇧',
  'code': 'SB',
  'phoneLength': 7
},
{
  'name': 'Somalia',
  'dialCode': '+252',
  'emoji': '🇸🇴',
  'code': 'SO',
  'phoneLength': 8
},
{
  'name': 'South Africa',
  'dialCode': '+27',
  'emoji': '🇿🇦',
  'code': 'ZA',
  'phoneLength': 9
},
{
  'name': 'South Sudan',
  'dialCode': '+211',
  'emoji': '🇸🇸',
  'code': 'SS',
  'phoneLength': 7
},
{
  'name': 'South Georgia and the South Sandwich Islands',
  'dialCode': '+500',
  'emoji': '🇬🇸',
  'code': 'GS',
  'phoneLength': 5
},
{
  'name': 'Spain',
  'dialCode': '+34',
  'emoji': '🇪🇸',
  'code': 'ES',
  'phoneLength': 9
},
{
  'name': 'Sri Lanka',
  'dialCode': '+94',
  'emoji': '🇱🇰',
  'code': 'LK',
  'phoneLength': 7
},
{
  'name': 'Sudan',
  'dialCode': '+249',
  'emoji': '🇸🇩',
  'code': 'SD',
  'phoneLength': 7
},
{ 'name': 'Suriname', 'dialCode': '+597', 'emoji': '🇸🇷', 'code': 'SR' },
{
  'name': 'Svalbard and Jan Mayen',
  'dialCode': '+47',
  'emoji': '🇸🇯',
  'code': 'SJ',
  'phoneLength': 8
},
{
  'name': 'Swaziland',
  'dialCode': '+268',
  'emoji': '🇸🇿',
  'code': 'SZ',
  'phoneLength': 8
},
{
  'name': 'Sweden',
  'dialCode': '+46',
  'emoji': '🇸🇪',
  'code': 'SE',
  'phoneLength': 7
},
{
  'name': 'Switzerland',
  'dialCode': '+41',
  'emoji': '🇨🇭',
  'code': 'CH',
  'phoneLength': 9
},
{
  'name': 'Syrian Arab Republic',
  'dialCode': '+963',
  'emoji': '🇸🇾',
  'code': 'SY',
  'phoneLength': 7
},
{
  'name': 'Taiwan',
  'dialCode': '+886',
  'emoji': '🇹🇼',
  'code': 'TW',
  'phoneLength': 9
},
{
  'name': 'Tajikistan',
  'dialCode': '+992',
  'emoji': '🇹🇯',
  'code': 'TJ',
  'phoneLength': 9
},
{
  'name': 'Tanzania, United Republic of Tanzania',
  'dialCode': '+255',
  'emoji': '🇹🇿',
  'code': 'TZ',
  'phoneLength': 7
},
{
  'name': 'Thailand',
  'dialCode': '+66',
  'emoji': '🇹🇭',
  'code': 'TH',
  'phoneLength': 9
},
{
  'name': 'Timor-Leste',
  'dialCode': '+670',
  'emoji': '🇹🇱',
  'code': 'TL',
  'phoneLength': 7
},
{
  'name': 'Togo',
  'dialCode': '+228',
  'emoji': '🇹🇬',
  'code': 'TG',
  'phoneLength': 8
},
{
  'name': 'Tokelau',
  'dialCode': '+690',
  'emoji': '🇹🇰',
  'code': 'TK',
  'phoneLength': 5
},
{
  'name': 'Tonga',
  'dialCode': '+676',
  'emoji': '🇹🇴',
  'code': 'TO',
  'phoneLength': 5
},
{
  'name': 'Trinidad and Tobago',
  'dialCode': '+1868',
  'emoji': '🇹🇹',
  'code': 'TT',
  'phoneLength': 7
},
{
  'name': 'Tunisia',
  'dialCode': '+216',
  'emoji': '🇹🇳',
  'code': 'TN',
  'phoneLength': 8
},
{
  'name': 'Turkey',
  'dialCode': '+90',
  'emoji': '🇹🇷',
  'code': 'TR',
  'phoneLength': 11
},
{
  'name': 'Turkmenistan',
  'dialCode': '+993',
  'emoji': '🇹🇲',
  'code': 'TM',
  'phoneLength': 8
},
{
  'name': 'Turks and Caicos Islands',
  'dialCode': '+1649',
  'emoji': '🇹🇨',
  'code': 'TC',
  'phoneLength': 10
},
{
  'name': 'Tuvalu',
  'dialCode': '+688',
  'emoji': '🇹🇻',
  'code': 'TV',
  'phoneLength': 5
},
{
  'name': 'Uganda',
  'dialCode': '+256',
  'emoji': '🇺🇬',
  'code': 'UG',
  'phoneLength': 7
},
{
  'name': 'Ukraine',
  'dialCode': '+380',
  'emoji': '🇺🇦',
  'code': 'UA',
  'phoneLength': 9
},
{
  'name': 'United Arab Emirates',
  'dialCode': '+971',
  'emoji': '🇦🇪',
  'code': 'AE',
  'phoneLength': 9
},
{
  'name': 'United Kingdom',
  'dialCode': '+44',
  'emoji': '🇬🇧',
  'code': 'GB',
  'phoneLength': 10
},
{
  'name': 'United States',
  'dialCode': '+1',
  'emoji': '🇺🇸',
  'code': 'US',
  'phoneLength': 10
},
{
  'name': 'Uruguay',
  'dialCode': '+598',
  'emoji': '🇺🇾',
  'code': 'UY',
  'phoneLength': 8
},
{
  'name': 'Uzbekistan',
  'dialCode': '+998',
  'emoji': '🇺🇿',
  'code': 'UZ',
  'phoneLength': 9
},
{
  'name': 'Vanuatu',
  'dialCode': '+678',
  'emoji': '🇻🇺',
  'code': 'VU',
  'phoneLength': 5
},
{
  'name': 'Venezuela, Bolivarian Republic of Venezuela',
  'dialCode': '+58',
  'emoji': '🇻🇪',
  'code': 'VE',
  'phoneLength': 7
},
{
  'name': 'Vietnam',
  'dialCode': '+84',
  'emoji': '🇻🇳',
  'code': 'VN',
  'phoneLength': 9
},
{
  'name': 'Virgin Islands, British',
  'dialCode': '+1284',
  'emoji': '🇻🇬',
  'code': 'VG',
  'phoneLength': 7
},
{
  'name': 'Virgin Islands, U.S.',
  'dialCode': '+1340',
  'emoji': '🇻🇮',
  'code': 'VI',
  'phoneLength': 10
},
{
  'name': 'Wallis and Futuna',
  'dialCode': '+681',
  'emoji': '🇼🇫',
  'code': 'WF',
  'phoneLength': 6
},
{
  'name': 'Yemen',
  'dialCode': '+967',
  'emoji': '🇾🇪',
  'code': 'YE',
  'phoneLength': 9
},
{
  'name': 'Zambia',
  'dialCode': '+260',
  'emoji': '🇿🇲',
  'code': 'ZM',
  'phoneLength': 9
},
{
  'name': 'Zimbabwe',
  'dialCode': '+263',
  'emoji': '🇿🇼',
  'code': 'ZW',
  'phoneLength': 9
}];
