<ng-container *ngIf="freeFC && paymentMethodFC">
  <label
    *ngFor="let pricingOption of pricingOptions; index as i;"
    for="pricing-option-{{i}}"
    class="surface-border border-1 p-3 mb-0 surface-0 flex align-items-center gap-3 cursor-pointer"
    [class.border-round-top]="i === 0"
    [class.border-round-bottom]="i === (pricingOptions.length || 0) - 1"
    [class.border-top-none]="i !== 0"
    >
    <p-radioButton inputId="pricing-option-{{i}}" [formControl]="freeFC" (onClick)="onPricingOptionSelect($event, pricingOption.value)" [value]="pricingOption.value"></p-radioButton>
    <div>
      <div class="font-bold text-xl">{{pricingOption.label! | translate}}</div>
      <p-selectButton *ngIf="pricingOption.value === false" [options]="paymentOptions" [formControl]="paymentMethodFC" optionLabel="label" optionValue="value">
        <ng-template let-item pTemplate>
          <span>{{ item.label | translate }}</span>
        </ng-template>
      </p-selectButton>
    </div>
  </label>
</ng-container>
