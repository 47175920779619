

<app-modal-header
  (close)="close()">
  <span slot="title">
    {{ title | translate }}
  </span>
</app-modal-header>

<div class="modal-body" style="white-space: pre-line;">
  <p>{{ text | translate:textData }}</p>


  <ng-container *ngIf="template">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </ng-container>
</div>

<div class="modal-footer">

  <button (click)="onResult(false)" pButton label="{{ cancelBtnLabel | translate }}" class="p-button-text p-button-plain"></button>
  <button (click)="onResult(true)" pButton label="{{ deleteBtnLabel | translate }}" class="p-button-danger"></button>

</div>
