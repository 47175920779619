import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { Countries, ICountry } from 'src/app/shared/enums/utils/countries';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { InputMaskModule } from 'primeng/inputmask';
import { UtilsService } from '../../services/utils.service';

interface IFormGroup {
  dialCode: FormControl<string>;
  phoneLength: FormControl<number>;
  phoneNumber: FormControl<string | null>;
}

interface ISelectItem {
  country: string;
  value: string;
  label: string;
  selectedLabel: string;
}

@Component({
  selector: 'app-phone-number-input',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, DropdownModule, InputMaskModule,
  ],
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss']
})
export class PhoneNumberInputComponent implements OnInit, OnDestroy {

  @Input({required: true}) FC?: FormControl<string | null>;
  @Input() placeholder?: string;
  @Input() required: boolean = false;
  @Input() markAsTouched$?: Subject<boolean>;

  form?: FormGroup<IFormGroup>;

  dialCodesOptions: ISelectItem[] = Countries.map((country) => {
    const translationCode = `country.${country.code.toLowerCase()}`;
    let countryNameTranslated = this.translateService.instant(`country.${country.code.toLowerCase()}`);
    if (countryNameTranslated === translationCode) {
      countryNameTranslated = country.name;
    }
    return {
      value: country.dialCode,
      label: `${country.emoji} ${countryNameTranslated.slice(0, 26) + (countryNameTranslated.length > 26 ? '...' : '')} ${country.dialCode}`,
      country: countryNameTranslated,
      selectedLabel: `${country.emoji} ${country.dialCode}`
    };
  });

  phoneNumberStatus: 'DISABLED' | 'VALID' | 'INVALID' | 'PENDING' = 'VALID';

  subs: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      dialCode: new FormControl('+420', { nonNullable: true }),
      phoneNumber: new FormControl<string | null>(null, { nonNullable: true, validators: this.required ? [Validators.required, maskValidator] : [maskValidator] }),
      phoneLength: new FormControl(9, { nonNullable: true })
    });
    if (this.FC?.value) {
      this.patchForm(this.FC.value);
    }
    this.subs.push(this.form.valueChanges.subscribe((form) => {
      if (form.phoneNumber && !form.phoneNumber.includes('_')) {
        this.FC?.setValue(`${form.dialCode}${form.phoneNumber}`);
      } else {
        this.FC?.setValue(null);
      }
      this.FC?.markAsDirty();
    }));
    this.subs.push(this.form.controls.phoneNumber.statusChanges.subscribe((status) => {
      this.phoneNumberStatus = status;
    }));
    this.subs.push(this.form.controls.dialCode.valueChanges.subscribe((dialCode) => {
      const country = Countries.find((country) => country.dialCode === dialCode);
      if (!country) {
        console.error('Country not found!');
      }
      this.form?.controls.phoneLength.setValue(country?.phoneLength || 10);
    }));

    this.subs.push(this.markAsTouched$?.subscribe((markAsTouched) => {
      if (markAsTouched) this.form?.controls.phoneNumber?.markAsDirty();
      if (this.form?.controls.phoneNumber.invalid) this.phoneNumberStatus = 'INVALID';
    }) || Subscription.EMPTY);
  }

  patchForm(value: string) {
    const possibleDialCodes = [
      value.slice(0, 2),
      value.slice(0, 3),
      value.slice(0, 4),
      value.slice(0, 5)
    ];
    const foundPossibleCountries: ICountry[] = [];
    possibleDialCodes.forEach((possibleDialCode) => {
      Countries.forEach((country) => {
        const phoneLength = country.phoneLength || 10;
        if (country.dialCode === possibleDialCode && (country.dialCode.length + phoneLength) === value.length) foundPossibleCountries.push(country);
      });
    });
    if (foundPossibleCountries.length) {
      if (foundPossibleCountries.length > 1) {
        this.utilsService.logError(`found more than 1 foundPossibleCountries for input: ${JSON.stringify(value)} - ${JSON.stringify(foundPossibleCountries)}`);
      }
      const country = foundPossibleCountries.at(0)!;
      this.form?.patchValue({
        dialCode: country.dialCode,
        phoneLength: country.phoneLength || 10,
        phoneNumber: value.slice(country.dialCode.length)
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

}

export function maskValidator(control: AbstractControl) {
  const FC = control as FormControl<string | null>;
  if (FC.value?.includes('_')) return { invalidMaskInput: true };

  return null;
}
