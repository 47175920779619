import { Pipe, PipeTransform } from '@angular/core';
import { IOrgUser } from '../models/user/user.model';
import { OrgUserNameOrEmailPipe } from './org-user-name-or-email.pipe';

@Pipe({
  name: 'orgUserInitials',
  standalone: true
})
export class OrgUserInitialsPipe implements PipeTransform {

  constructor(
    private orgNameOrEmailPipe: OrgUserNameOrEmailPipe
  ) {  }

  transform(user: Partial<IOrgUser> | { name?: string | null; surname?: string | null; } | undefined): string {
    const x = this.orgNameOrEmailPipe.transform(user);
    const split = x.toUpperCase().split(' ');
    return `${split[0] ? split[0][0] : '' }${split[1] ? split[1][0] : ''}`;
  }

}
