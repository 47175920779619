import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';
import { IPrice } from 'src/app/shared/models/price/price.model';

export interface ICallablesOffersGetByIdData {
  id?: number;
  uuid?: string;

  include?: {
    offerItems?: boolean;
    order?: boolean;
    contactUsers?: boolean;
    brandProfile?: boolean;
    organization?: boolean;
    invoiceProfile?: boolean;
    user?: boolean;
  }
}

export interface IOfferUpdateSectionData {
  offerId: number;
  section: OfferUpdateSectionType;
  data: IOfferUpdatableSectionsData;
};
export enum OfferUpdateSectionType {
  ACCOMPANYING_TEXT = 'ACCOMPANYING_TEXT',
  DETAILS = 'DETAILS',
};
type IOfferUpdatableSectionsData =
  IOfferAccompanyingTextSectionData |
  IOfferDetailsSectionData;
export interface IOfferAccompanyingTextSectionData {
  title: string;
  description: string | null;
};
export interface IOfferDetailsSectionData {
  scheduledStart: Date | null;
  invoiceProfileId: number;
  secondsToOrder: number;
  secondsToMaturity: number;
  secondsToPay: number;
};

interface IOfferCanModifyData {
  offerIds?: number[];
  offerUuids?: string[];
}

interface ICallablesOffersGetOrgShopItemsData {
  organizationId: number;
}

export interface ICallablesOffersAddOfferItemsData {
  offerUuid: string;
  shopItemIds: number[];
}

export interface ICallablesOffersIssueData {
  offerUuid: string;

  sendMail: boolean;
  template?: {
    sender: string;
    subject: string;
    message: string;
  }
}

export interface ICallablesOffersGetOrgContactUsersData {
  offerUuid: string;
}

export interface ICallablesOffersAddContactUsersData {
  offerUuid: string;
  userIds: number[];
}

export interface ICallablesOfferItemUpdateData {
  shopItemId: number;
  offerUuid: string;

  title: string;
  shortDescription: string | null;
  description: string | null;
  invoiceProfileId: number;
  price: IPrice[];
  vat: number;
  // dalsi
};

export interface ICallablesOffersRemoveOfferItemsData {
  shopItemIds: number[];
  offerUuid: string;
}

export interface ICallablesOffersCancelData {
  offerUuid?: string;
  offerId?: number;
  sendNotifications: boolean;
  stornoFee: number;
}

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  constructor(
    private dbService: DbService,
  ) { }

  public removeOfferItems(data: ICallablesOffersRemoveOfferItemsData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOffersRemoveOfferItems, data });
    return this.dbService.handleObs(obs);
  }

  public updateOfferItem(data: ICallablesOfferItemUpdateData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOfferItemUpdate, data });
    return this.dbService.handleObs(obs);
  }

  public addContactUsers(data: ICallablesOffersAddContactUsersData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOffersAddContactUsers, data });
    return this.dbService.handleObs(obs);
  }

  public getPossibleContactUsers(data: ICallablesOffersGetOrgContactUsersData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOffersGetOrgContactUsers, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public userCanChangeOffer(data: IOfferCanModifyData): Observable<boolean> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOffersCanModify, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public issue(data: ICallablesOffersIssueData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOffersIssue, data });
    return this.dbService.handleObs(obs);
  }

  public getById(data: ICallablesOffersGetByIdData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOffersGetById, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public updateSection(data: IOfferUpdateSectionData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOffersUpdateSection, data });
    return this.dbService.handleObs(obs);
  }

  public addItems(data: ICallablesOffersAddOfferItemsData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOffersAddOfferItems, data });
    return this.dbService.handleObs(obs);
  }

  public getItemsToAdd(data: ICallablesOffersGetOrgShopItemsData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOffersGetOrgShopItems, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public cancel(data: ICallablesOffersCancelData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesOffersCancel, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }
}
