import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IReservation } from 'src/app/shared/models/reservation/reservation.model';
import { ReservationState } from 'src/app/shared/enums/reservation/reservation-states.enum';
import { LocalizedDatePipe } from 'src/app/shared/pipes/localized-date.pipe';
import { RecordPaymentModalComponent } from 'src/app/pages/admin/org-admin/payments-tabs/payments/record-payment-modal/record-payment-modal.component';
import { IUser } from 'src/app/shared/models/user/user.model';
import { ModalService } from 'src/app/shared/services/modal.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-reservation-status',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,

    LocalizedDatePipe,
  ],
  templateUrl: './reservation-status.component.html',
  styleUrls: ['./reservation-status.component.scss']
})
export class ReservationStatusComponent {
  ReservationState = ReservationState;

  @Input() reservation: IReservation | undefined;

  constructor(
    private modalService: ModalService
  ) { }

  onRecordPayment(orderId: number, user: IUser) {
    this.modalService.openRecordPaymentModal(RecordPaymentModalComponent, {
      orderId,
      user
    });
  }
}
