import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ICallablesUserDataUpdate } from 'src/app/shared/services/entities/users/customer/customers.service';
import { CustomerCardInvoker, CustomerCardStoreService, ICustomerCardState } from 'src/app/shared/services/store/customer-card-store.service';
import { Subscription, take } from 'rxjs';
import { CustomDataInputsComponent, IOrgUserNameFG } from 'src/app/pages/admin/components/custom-data-inputs/custom-data-inputs.component';
import { SelectedOrgService } from 'src/app/shared/services/selected-org.service';
import { UserHelperService } from 'src/app/shared/services/helpers/user-helper.service';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { HiddenInputWrapComponent } from 'src/app/shared/components/hidden-input-wrap/hidden-input-wrap.component';

@Component({
  selector: 'app-customer-card-customer',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule, ProgressSpinnerModule,
    CustomDataInputsComponent, TranslateModule, ReactiveFormsModule,
    InputTextModule, HiddenInputWrapComponent
  ],
  templateUrl: './customer-card-customer.component.html',
  styleUrls: ['./customer-card-customer.component.scss']
})
export class CustomerCardCustomerComponent implements OnInit, OnDestroy {
  

  triStateOptions: SelectItem[] = [
    {
      icon: 'pi pi-check text-xs',
      value: true
    },
    {
      icon: 'pi pi-times text-xs',
      value: false
    }
  ];

  state!: ICustomerCardState;
  customDataFG = this.utilsService.createCustomDataFG([]);

  nameFG: IOrgUserNameFG = new FormGroup({
    name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    surname: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] })
  });

  emailFC?: FormControl<string>;

  subs: Subscription[] = [];

  constructor(
    private utilsService: UtilsService,
    private customerCardStore: CustomerCardStoreService,
    private selectedOrgService: SelectedOrgService,
    private uHelper: UserHelperService
  ) {
    this.state = this.customerCardStore.state;
  }

  ngOnInit(): void {
    this.subs.push(
      this.state.customerCard$.subscribe((customerCard) => {
        const userNameObj = customerCard?.user ? this.uHelper.getOrgUserName(customerCard?.user, this.selectedOrgService.getCurrentValue()) : null;
        if (userNameObj) {
          this.nameFG.controls.name.setValue(userNameObj.name);
          this.nameFG.controls.surname.setValue(userNameObj.surname);
        }
        this.customDataFG = this.utilsService.createCustomDataFG(customerCard?.userData ?? []);
        if (this.customerCardStore.invoker === CustomerCardInvoker.LECTURER) this.customDataFG.disable();
        if (customerCard?.user?.email) {
          this.emailFC = new FormControl<string>(customerCard.user.email, { nonNullable: true, validators: [Validators.required] });
        }
      })
    );
  }

  onSaveCustomData() {
    this.utilsService.markFormGroupDirty(this.customDataFG);
    this.utilsService.markFormGroupDirty(this.nameFG);
    if (this.emailFC) this.utilsService.markControlDirty(this.emailFC);

    if (this.customDataFG.invalid || this.state?.updatingUserData$.getValue() || this.nameFG.invalid || this.emailFC?.invalid) {
      console.error('invalid');
      return;
    };

    const { userId, orgId } = this.customerCardStore.initData ? this.customerCardStore.initData : { userId: null, orgId: null };
    if (!userId || !orgId) return;

    const formVals = this.customDataFG.getRawValue();

    const data: ICallablesUserDataUpdate = {
      userId: userId,
      organizationId: orgId,
      orgUserName: this.nameFG.getRawValue(),
      email: this.emailFC?.getRawValue(),
      data: Object.entries(formVals).map(([collectedUserDataId, userDataValue]) => {
        return {
          collectedUserDataId: parseInt(collectedUserDataId),
          userDataValue: userDataValue
        }
      })
    };

    this.customerCardStore.updateCustomData(data).pipe(
      take(1),
    ).subscribe({
      next: () => {
        this.customDataFG.markAsPristine();
        this.nameFG.markAsPristine();
        this.emailFC?.markAsPristine();
      }
    });
  }


  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
