import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ModalHeaderComponent } from '../components/modal-header/modal-header.component';
import { Modal } from '../modal';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-delete-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    ModalHeaderComponent, TranslateModule
  ],
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss']
})
export class ConfirmDeleteModalComponent extends Modal implements OnInit, OnDestroy {

  @Input() title: string = '';
  @Input() text: string = '';
  @Input() textData: any = {};
  @Input() deleteBtnLabel: string = '';
  @Input() cancelBtnLabel: string = '';
  @Input() template: TemplateRef<any> | undefined;

  @Output() result = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  onResult(res: boolean) {
    this.result.emit(res);
    this.close();
  }


  ngOnDestroy(): void {
    this.result.emit(false);
  }
}
