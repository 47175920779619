

<ng-container [ngSwitch]="paymentStatus">


  <div class="wrap shadow-box" [class.in-iframe]="inIframe">

      <ng-container *ngSwitchCase="PaymentGatePaymentStatus.PAID">

        <ng-container *ngTemplateOutlet="iconTemplate, context:{icon:'pi-thumbs-up', bg: 'bg-green-500'}"></ng-container>

        <ng-container *ngTemplateOutlet="titleTemplate, context:{title:'payment-gate-payment-status.PAID.title' | translate}"></ng-container>

        <p class="mt-0 mb-4 text-bluegray-400">
          {{'payment-gate-payment-status.PAID.sending-email-to' | translate}}<br>
        </p>

        <ng-container *ngIf="order && !waitUntilSuccess">
          <span class="text-bluegray-400">
            {{'payment-gate-payment-status.PAID.total-paid' | translate}}
          </span>
          <h4 class="m-0 text-dark-blue font-semibold">
            {{ [ order.summary.totalPaid ] | price | async }}
          </h4>
        </ng-container>

        <ng-container *ngTemplateOutlet="orderPreview"></ng-container>

        <ng-container *ngTemplateOutlet="invoiceBtn"></ng-container>
        
        <app-finish-btn></app-finish-btn>

      </ng-container>

      <ng-container *ngSwitchCase="PaymentGatePaymentStatus.CANCELLED">

        <ng-container *ngTemplateOutlet="iconTemplate, context:{icon:'pi-times', bg: 'bg-bluegray-400'}"></ng-container>

        <ng-container *ngTemplateOutlet="titleTemplate, context:{title:'payment-gate-payment-status.CANCELLED.title' | translate}"></ng-container>

        <ng-container *ngTemplateOutlet="fillSpace"></ng-container>

        <ng-container *ngTemplateOutlet="invoiceBtn"></ng-container>
        
        <button
          *ngIf="inIframe"
          (click)="onPay()"
          pButton
          label="{{'payment-gate-payment-status.CANCELLED.pay-btn-label' | translate}}"
          class="p-button-sm p-button-success {{ buttonWidthClass }}">
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="PaymentGatePaymentStatus.PENDING">

        <ng-container *ngTemplateOutlet="iconTemplate, context:{icon:'pi-clock', bg: 'bg-orange-400'}"></ng-container>

        <ng-container *ngTemplateOutlet="titleTemplate, context:{title:'payment-gate-payment-status.PENDING.title' | translate}"></ng-container>

        
        @if (order) {
          <p class="mt-0 mb-4 text-bluegray-400 w-9">
            {{'payment-gate-payment-status.PENDING.call-for-payment-text' | translate: {date: order.maturity | localizedDate:'shortDate'} }}<br>
          </p>

          <span class="text-bluegray-400">
            {{'payment-gate-payment-status.PENDING.to-pay-label' | translate}}
          </span>
          <h4 class="m-0 text-dark-blue font-semibold">
            {{ [ order.summary.finalPrice ] | price | async }}
          </h4>
        }

        <ng-container *ngTemplateOutlet="orderPreview"></ng-container>


        <button
          *ngIf="inIframe || showPayBtn"
          (click)="onPay()"
          pButton
          [label]="('payment-gate-payment-status.PENDING.pay-btn-label' | translate) + (order ? ' ' + ([order.summary.finalPrice] | price | async) : '')"
          class="p-button p-button-success {{ buttonWidthClass }} shine">
        </button>

        <ng-container *ngTemplateOutlet="invoiceBtn"></ng-container>

      </ng-container>

      <ng-container *ngSwitchCase="PaymentGatePaymentStatus.UNKNOWN">

        <ng-container *ngTemplateOutlet="iconTemplate, context:{icon:'pi-clock', bg: 'bg-orange-400'}"></ng-container>

        <ng-container *ngTemplateOutlet="titleTemplate, context:{title:'payment-gate-payment-status.UNKNOWN.title' | translate}"></ng-container>

        <p-progressSpinner class="mt-3"></p-progressSpinner>

      </ng-container>

  </div>

</ng-container>


<ng-template #iconTemplate let-icon="icon" let-bg="bg">
  <i class="w-4rem h-4rem pi {{ icon }} {{ bg }} flex-shrink-0 flex align-items-center justify-content-center p-2 border-circle text-white" style="font-size: 2rem"></i>
</ng-template>

<ng-template #titleTemplate let-title="title">

  <h3 class="my-2 text-dark-blue font-semibold">
    {{ title }}
  </h3>

</ng-template>

<ng-template #invoiceBtn>

  <div class="mb-2 w-full">
    <app-show-invoice-or-orders-btn
      [order]="order ?? null"
      [transfer]="transfer ?? null"
      [PG_CODE]="PG_CODE ?? null"
      [singleBtnWidthClass]="buttonWidthClass">
    </app-show-invoice-or-orders-btn>
  </div>

</ng-template>


<ng-template #orderPreview>
  <div style="flex: 1 1 auto; overflow-y: auto; width: 100%; padding-top: 2.5rem; margin-bottom: 1rem;">
    <ng-container *ngIf="order && !waitUntilSuccess">
      <app-order-list-item
        [order]="order"
        [collapsed]="false"
        class="w-full text-left order-item-border"
        >
      </app-order-list-item>
    </ng-container>
    <p-progressSpinner *ngIf="fetchingTransfer || fetchingOrder || waitUntilSuccess" class="mt-3"></p-progressSpinner>
  </div>
</ng-template>

<ng-template #fillSpace>
  <div style="flex: 1 1 auto; overflow: auto;"></div>
</ng-template>