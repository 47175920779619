




<div *ngIf="item" class="py-2">

  <div class="flex justify-content-between column-gap-1 align-items-center">
  
    <div class="flex flex-column justify-content-between">
      <h5 class="m-0">
        {{ item.shopItem.title }}
      </h5>


      <div *ngIf="item.sessionAttendance.session as s" class="details-block-wrap">
        <div *ngIf="s.startAt" class="detail-block">
          {{ s.startAt | localizedDate:'EEEEEE':undefined:'UTC+0' }}
          {{ s.startAt | localizedDate:'shortTime':undefined:'UTC+0' }}
        </div>

        <div *ngIf="s.startAt" class="detail-block">
          {{ s.startAt | localizedDate:'shortDate':undefined:'UTC+0' }}
        </div>

        <div class="detail-block">
          {{ s.location | locationName:{ useInternalTitle: true, isOnline: s.format===SessionFormat.ONLINE } }}
        </div>
      </div>

      <div *ngIf="item.sessionAttendance.session as s" class="details-block-wrap">
        <div class="detail-block">
          {{ 'admin.excused-for-replacement-item.session-label' | translate:{nth: s.index + 1} }}
        </div>
      </div>
      
    </div>

    <div>
      <button
        *ngIf="!$any(item.sessionAttendance)?.replaced"
        [loading]="replacing"
        (click)="onReplace(item)"
        pButton
        label="{{ 'admin.excused-for-replacement-item.replace-btn.label' | translate }}"
        class="p-button-sm p-button-text p-button-warning">
      </button>

      <button
        *ngIf="$any(item.sessionAttendance)?.replaced"
        [disabled]="true"
        pButton
        label="{{ 'admin.excused-for-replacement-item.replaceed-btn.label' | translate }}"
        class="p-button-sm p-button-text">
      </button>
    </div>
  
  </div>

</div>



