import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'priceCount',
  standalone: true,
})
export class PriceCountPipe implements PipeTransform {

  constructor(
  ) {}

  transform(price?: number | string | null, count?: number | null): Observable<string> {
    if (!price) return of('');
    if (!count || count === 1) {
      return of(`${price}`);
    } else {
      return of(`${count}x ${price}`);
    }
  }
}
