import { ReservationState } from "../../enums/reservation/reservation-states.enum";
import { INote } from "../_other/note.model";
import { IOrder } from "../order/order-model";
import { IReservation } from "../reservation/reservation.model";
import { ISession } from "../session-group/session.model";
import { IShopItem } from "../shop-item/shop-item.model";
import { IUser } from "../user/user.model";

export enum SessionAttendanceState {
  PRESENT = 'PRESENT',
  ABSENT = 'ABSENT',
}
export enum SessionAttendanceType {
  SUBSTITUTION = 'SUBSTITUTION',
  REGULAR = 'REGULAR',
}

export interface ISessionAttendance {
  id: number;
  uuid: string;
  state: SessionAttendanceState | null;
  excused: boolean;
  replaced: boolean;
  type: SessionAttendanceType;
  notes: INote[];

  userId: number;
  user?: IUser;
  sessionId: number;
  session?: ISession;

  createdAt: string;
  updatedAt: string;
}


export interface ISessionAttendanceFE {
  session: ISession;
  shopItem: IShopItem;

  attendances: {
    [ReservationState.CONFIRMED]: IUserSessionAttendanceFE[];
    [ReservationState.APPROVED]: IUserSessionAttendanceFE[];
  }
}

export interface IUserSessionAttendanceFE {
  user: IUser;
  sessionAttendances: ISingleSessionAttendanceFE[];
  reservation: IReservation & { order: IOrder | null };
}
export type ISingleSessionAttendanceFE = (ISessionAttendance & { session: ISession, noAttendanceYet: false } |
  { noAttendanceYet: true, session: ISession });