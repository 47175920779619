import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomerNotesService } from 'src/app/shared/services/entities/customer-notes/customer-notes.service';
import { SelectedOrgService } from 'src/app/shared/services/selected-org.service';
import { CustomerNoteComponent } from './customer-note/customer-note.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SiItemNoteEditorComponent } from '../../../../events/admin-event/components/shop-item-notes-modal/si-item-note-editor/si-item-note-editor.component';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ICustomerNote } from 'src/app/shared/models/customer-note/customer-note.model';
import { take } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Portal, TemplatePortal } from '@angular/cdk/portal';

interface ICustomermNoteFG {
  content: FormControl<string>;
}

@Component({
  selector: 'app-customer-card-notes',
  standalone: true,
  imports: [
    TranslateModule,
    ButtonModule,
    CustomerNoteComponent, SiItemNoteEditorComponent,
  ],
  templateUrl: './customer-card-notes.component.html',
  styleUrl: './customer-card-notes.component.scss'
})
export class CustomerCardNotesComponent implements OnDestroy {

  @Input({required: true}) customerId: number | undefined;

  @Output() setFooterPortal = new EventEmitter<Portal<any> | null>();

  @ViewChild('scrollElement') scrollElement: ElementRef | undefined;
  @ViewChild('footerEl') footerEl: TemplateRef<any> | undefined;

  footerPortal: Portal<any> | undefined;

  form = new FormGroup<ICustomermNoteFG>({
    content: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] })
  });

  notes: ICustomerNote[] = [];
  sending: boolean = false;
  fetching: boolean = false;
  totalCount: number = 0;
  page: number = 0;
  pageSize: number = 10;

  constructor(
    private selectedOrg: SelectedOrgService,
    private customerNotesService: CustomerNotesService,
    private utilsService: UtilsService,
    private _viewContainerRef: ViewContainerRef
  ) {

  }

  ngOnInit(): void {
    this.fetchNotes();
  }

  ngAfterViewInit() {
    if (this.footerEl) {
      this.footerPortal = new TemplatePortal(this.footerEl, this._viewContainerRef);
      this.setFooterPortal.emit(this.footerPortal);
    } 
  }

  scrollToBottom(): void {
    if (this.scrollElement) {
      this.scrollElement.nativeElement.scrollTop = this.scrollElement.nativeElement.scrollHeight;
    }
  }

  onScroll(e: Event, el: HTMLElement) {
    const scrollTop = Math.abs(el.scrollTop);
    if ((scrollTop < 333) && !this.fetching && this.notes.length < this.totalCount) {
      this.fetchOlderNotes();
    }
  }

  onCreateNote() {
    this.utilsService.markFormGroupDirty(this.form);
    const orgId = this.selectedOrg.getCurrentValue();
    if (this.form.invalid || this.sending || !orgId || !this.customerId) return;

    this.sending = true;

    const formVals = this.form.getRawValue();

    this.customerNotesService.create({
      customerId: this.customerId,
      orgId,
      content: formVals.content,
      title: null,
    }).pipe(
      take(1),
    ).subscribe({
      next: (note) => {
        this.notes.unshift(note);
        this.totalCount++;
        this.sending = false;
      
        this.form.reset();
      },
      error: () => {
        this.sending = false;
      }
    })
  }

  onItemDetelted(itemUuid: string) {
    const idx = this.notes.findIndex(x => x.uuid === itemUuid);
    if (idx === -1) return;

    this.notes.splice(idx, 1);
    this.totalCount--;
  }

  private fetchOlderNotes() {
    this.page++;
    this.fetchNotes();
  }

  private fetchNotes() {
    const orgId = this.selectedOrg.getCurrentValue();
    if (!orgId || !this.customerId) return;

    this.fetching = true;
    this.customerNotesService.getCustomerNotesPagination({
      orgId,
      customerId: this.customerId,
      page: this.page,
      pageSize: this.pageSize
    }).subscribe((res) => {
      this.notes.push(...res.customerNotes);
      this.totalCount = res.totalCount;
      this.fetching = false;

      if (this.page === 0) {
        setTimeout(() => {
          this.scrollToBottom();
        })
      } else {
        // scroll 1px down to trigger onScroll event 
        this.scrollElement?.nativeElement.scrollTo(0, 1);
      }
    });
  }

  ngOnDestroy(): void {
    this.footerPortal?.detach();
    this.setFooterPortal.emit(null);
  }

}
