@if ((userData.params.selectionLimit === SelectionLimit.ONE_ITEM) || (!userData.params.selectionLimit)) {
  <p-dropdown
    [options]="options"
    [(ngModel)]="dropdownNgModel"
    [class.ng-invalid]="FC.invalid"
    [class.ng-dirty]="FC.dirty"
    optionLabel="label"
    optionValue="value"
    [filter]="false"
    [overlayOptions]="getOverlayOptions()"
    [placeholder]="userData.title"
    (onChange)="onDropdownChange($event)"
    styleClass="w-full"
  >
  </p-dropdown>
}
@if (userData.params.selectionLimit === SelectionLimit.MULTIPLE_ITEMS) {
  <p-multiSelect
    [formControl]="$any(FC)"
    [options]="options"
    optionLabel="label"
    optionValue="value"
    [filter]="false"
    [showToggleAll]="false"
    [showHeader]="false"
    [placeholder]="userData.title"
    styleClass="w-full"
  ></p-multiSelect>
}
