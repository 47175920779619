

<app-modal-header
  (close)="close()">
  <span slot="title" *ngIf="modalType === RecordPaymentModalType.RECORD_PAYMENT">
    {{ 'admin.payments.record-payment-modal.record-payment.title' | translate }}
  </span>
  <span slot="title" *ngIf="modalType === RecordPaymentModalType.PAIR_PAYMENT">
    {{ 'admin.payments.record-payment-modal.pair-payment.title' | translate }}
  </span>
</app-modal-header>

<div [formGroup]="form" class="modal-body grid">

  <div class="col-12">
    <span ngbAutofocus></span>

    <p-autoComplete
      #queryInput
      [minLength]="3"
      [autoHighlight]="true"
      [suggestions]="userSuggestions"
      field="label"
      formControlName="usersQuery"
      placeholder="{{ 'admin.payments.record-payment-modal.user-search.placeholder' | translate }}"
      (onSelect)="onUserSuggestionSelect($event)"
      (completeMethod)="whisper($event)"
      [completeOnFocus]="true"
      styleClass="w-full"
      inputStyleClass="w-full">
      <ng-template let-user pTemplate="item">
        {{ user.label }}
      </ng-template>
    </p-autoComplete>

  </div>

  <ng-container *ngIf="selectedUserFC.value">
    <div class="col-12">
      <p class="m-0 mb-1">{{ 'admin.payments.record-payment-modal.payment-target.label' | translate }}</p>
      <p-selectButton formControlName="addCredit" [options]="addCreditOptions" optionLabel="label" optionValue="value">
        <ng-template let-item pTemplate>
          {{ item.label | translate }}
        </ng-template>
      </p-selectButton>
    </div>
  
    <ng-container *ngIf="addCreditFC.value !== null">
      <div class="col-12">
        <p class="m-0 mb-1">{{ 'admin.payments.record-payment-modal.note.label' | translate }}</p>
        <textarea id="note" formControlName="note" placeholder="{{ 'admin.payments.record-payment-modal.note.label' | translate }}" pInputTextarea class="w-full"></textarea>
      </div>
    
      <div class="col-12" *ngIf="addCreditFC.value === false">
        <ng-container *ngIf="fetchingTransfers; else unpaidTransfersEl">
          <p-progressSpinner></p-progressSpinner>
        </ng-container>
        <ng-template #unpaidTransfersEl>
          <p class="m-0 mb-1">{{ 'admin.payments.record-payment-modal.select-transfer.label' | translate }}:</p>
          <p-listbox
            [options]="unpaidTransfers"
            optionDisabled="disabled"
            formControlName="selectedUnpaidTransfer"
            emptyMessage="{{ 'admin.payments.record-payment-modal.no-transfer.label' | translate }}"
            [listStyle]="{'max-height':'250px'}">
            <ng-template let-transfer pTemplate="item">
              <div class="flex justify-content-between w-full pointer-events-auto" [ngbTooltip]="transfer.disabled ? ('admin.payments.record-payment-modal.different-currency.tooltip' | translate) : null" container="body">
                <div>
                  <p class="font-bold m-0">
                    {{ transfer.event?.title }}
                  </p>
                  <p class="m-0">
                    {{ 'admin.payments.record-payment-modal.order-number.label' | translate:{ orderNumber: ''+transfer.order.id + transfer.order.randomNumber } }}
                  </p>
                </div>
    
                <div class="text-center">
                  <p-tag value="{{ 'admin.payments.record-payment-modal.remains-to-pay.label' | translate:{ price:[transfer.unpaid] | price | async } }}" severity="warning"></p-tag>
    
                  <p class="text-sm m-0">{{ 'admin.payments.record-payment-modal.remains-to-pay-until.label' | translate:{ date: transfer.expiration | localizedDate:'shortDate' } }}</p>
                </div>
              </div>
    
            </ng-template>
          </p-listbox>
        </ng-template>
      </div>
    
      <ng-container *ngIf="(selectedUnpaidTransferFC.value || addCreditFC.value) && form.controls.amount && form.controls.currency">
        <div class="col-8 flex">
          <p-inputNumber
            appNumberInput
            [formControl]="form.controls.amount"
            placeholder="{{ 'admin.payments.record-payment-modal.amount.placeholder' | translate }}"
            [minFractionDigits]="2"
            [maxFractionDigits]="2"
          />
          <p-dropdown formControlName="currency" [options]="currencyOptions" optionLabel="value" optionValue="value" class="m-0 ml-2"></p-dropdown>
        </div>
      </ng-container>

      @if (selectedUnpaidTransferFC.value && form.controls.paidAt) {
        <div class="col-6 flex flex-column">
          <p class="m-0">Datum platby</p>
          <p-calendar
            [showTime]="false"
            [showIcon]="false"
            formControlName="paidAt"
            [dateFormat]="'primeng.dateFormat' | translate"
            inputId="paidAt"
            appendTo="body"
            [baseZIndex]="9999999999"
            class="flex w-full"
            styleClass="flex w-full">
          </p-calendar>
        </div>
      }
    </ng-container>
  </ng-container>

</div>

<div class="modal-footer">

  <button
    *ngIf="addCreditFC.value !== null"
    (click)="onSubmit()"
    [loading]="sending"
    pButton
    label="{{ 'admin.payments.record-payment-modal.confirm-btn.label' | translate }}"
    class="p-button-sm">
  </button>

</div>
