import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'age',
  standalone: true,
})
export class AgePipe implements PipeTransform {

  constructor(
    public utilsService: UtilsService
  ) {}

  transform(birthDate?: Date | string | null): Observable<string> {
    if (!birthDate) return of('');
    if (typeof birthDate === 'string') birthDate = new Date(birthDate);
    const now = new Date();
    const numberOfLeapYear = this.utilsService.getNumberOfLeapYears(birthDate.getFullYear(), now.getFullYear());
    const ageMs = now.getTime() - birthDate.getTime();
    const ageYears = Math.floor(ageMs / ((1000 * 60 * 60 * 24 * 365) + numberOfLeapYear * (1000 * 60 * 60 * 24)));
    switch (true) {
      case (ageYears < 1):
        return of('<1 rok');
      case (ageYears === 1):
        return of('1 rok');
      case (ageYears <= 4):
        return of(`${ageYears} roky`);
      case (ageYears > 4):
        return of(`${ageYears} let`);
      default:
        throw { caseShomehowNotImplemented: 'qwfqwf' };
    }
  }
}
