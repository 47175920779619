import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Editor, EditorInitEvent, EditorModule } from 'primeng/editor';
import { DeviceType, SystemService } from 'src/app/shared/services/system.service';

@Component({
  selector: 'app-si-item-note-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule, TranslateModule,
    EditorModule,
  ],
  templateUrl: './si-item-note-editor.component.html',
  styleUrl: './si-item-note-editor.component.scss'
})
export class SiItemNoteEditorComponent {

  @Input({required: true}) FC: FormControl<string> | undefined;

  @ViewChild('ed') editor: Editor | undefined;

  constructor(
    private sysService: SystemService
  ) {}


  onEditorInit(e: EditorInitEvent) {
    if (this.sysService.deviceType$.value === DeviceType.DESKTOP) {
      e.editor?.setSelection(e.editor?.getLength(), 0)
      e.editor?.focus();
    }
  }
}
