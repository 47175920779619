
import { Component } from "@angular/core";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  template: ''
})
export class Modal {

  ref: NgbModalRef | undefined;

  close() {
    this.ref?.close();
  }
}
