import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Color } from 'src/app/shared/enums/utils/colors';

@Component({
  selector: 'app-customer-order-item-wrap',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './customer-order-item-wrap.component.html',
  styleUrls: ['./customer-order-item-wrap.component.scss']
})
export class CustomerOrderItemWrapComponent {

  @Input() bgColor: Color | undefined;
  @Input() textColorClass: string | undefined;

}
