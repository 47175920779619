<ng-container *ngIf="invoiceTypeFC">
  <div class="flex">
    <p-dropdown
      inputId="invoiceType"
      [formControl]="invoiceTypeFC"
      class="w-full"
      styleClass="w-full"
      inputStyleClass="w-full"
      [options]="invoiceTypeOptions"
      optionLabel="label"
      optionValue="value"
    >
      <ng-template let-item pTemplate="selectedItem">
        <div>{{ item.label | translate }}</div>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <div>{{ item.label | translate }}</div>
      </ng-template>
    </p-dropdown>
  </div>
</ng-container>