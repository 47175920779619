import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IUser } from 'src/app/shared/models/user/user.model';
import { IAdditionalUserData } from 'src/app/shared/services/store/org-reservations-store.service';
import { Subject } from 'rxjs';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipCopyDirective } from 'src/app/shared/directives/tooltip-copy.directive';
import { UserNameOrEmailPipe } from 'src/app/shared/pipes/user-name-or-email.pipe';

@Component({
  selector: 'td[app-reserver-cell]',
  standalone: true,
  imports: [
    CommonModule, TranslateModule,
    UserNameOrEmailPipe,
    TooltipCopyDirective,
  ],
  templateUrl: './reserver-cell.component.html',
  styleUrls: ['./reserver-cell.component.scss']
})
export class ReserverCellComponent {

  @Input({required: true}) organizationId!: number;
  @Input({required: true}) reserver: IUser | undefined;
  @Input() additionalUserData: IAdditionalUserData | undefined;
  @Input() hasAnyCustomerNotes: boolean = false;

  @Input() reserverClickListener: Subject<IUser | undefined> | undefined;
  @Input() commentClickListener: Subject<IUser | undefined> | undefined;




}
