


<div *ngIf="order as o" class="order-wrap flex flex-column surface-card p-3 border-round border-1 border-100" [class.loading-box]="loading">

  <div (click)="onItem()" class="flex cursor-pointer">
    <div class="flex flex-column flex-1 row-gap-1">
      <h5 class="m-0 text-dark-blue">
        {{ 'admin.customer-card-order.order-label' | translate }} #{{o.orderId}}{{ o.randomNumber }}
      </h5>
  
      <span *ngIf="order.state === OrderState.COMPLETED" class="flex align-items-center text-green-500">
        <i class="pi pi-check mr-2"></i>
        <span>
          {{ 'admin.customer-card-order.order.state.paid' | translate}}
        </span>
      </span>
      <span *ngIf="!orderHelper.isOverdue(order.maturity) && order.state === OrderState.PENDING" class="flex align-items-center text-orange-400">
        <i class="pi pi-clock mr-2"></i>
        <span>
          {{ 'admin.customer-card-order.order.state.waiting-for-payment' | translate:{date: o.maturity | localizedDate:'d.M.'} }}
        </span>
      </span>
      <span *ngIf="!orderHelper.isExpired(order.expiration) && orderHelper.isOverdue(order.maturity) && order.state === OrderState.PENDING" class="flex align-items-center text-red-500">
        <i class="pi pi-exclamation-triangle mr-2"></i>
        <span>
          {{ 'admin.customer-card-order.order.state.overdue' | translate:{date: o.expiration | localizedDate:'d.M.'} }}
        </span>
      </span>
      <span *ngIf="orderHelper.isExpired(order.expiration) && order.state === OrderState.PENDING" class="flex align-items-center text-red-500">
        <i class="pi pi-exclamation-triangle mr-2"></i>
        <span>
          {{ 'admin.customer-card-order.order.state.expired' | translate:{date: o.expiration | localizedDate:'d.M.'} }}
        </span>
      </span>
      <span *ngIf="order.state === OrderState.CANCELLED" class="flex align-items-center text-color-secondary">
        <i class="pi pi-times mr-2"></i>
        <span>
          {{ 'admin.customer-card-order.order.state.cancelled' | translate:{ date: o.cancelledAt | localizedDate:'d.M.' } }}
        </span>
      </span>
    </div>
  
    <div class="flex align-items-center">
  
      <div class="flex align-items-center">

        <div (click)="onOpenOrderPage(o, $event)" ngbTooltip="{{ 'admin.customer-card-order.order.view.tooltip' | translate }}" class="small-icon-button">
          <i class="pi pi-eye relative z-1"></i>
        </div>
        <div *ngIf="o.companyBillingInfo || o.invoices.length" (click)="onOpenOrderInvoicePage(o, $event)" ngbTooltip="{{ 'admin.customer-card-order.order.view-invoice.tooltip' | translate }}" class="small-icon-button">
          <i class="pi pi-file relative z-1"></i>
        </div>
  
        <span class="flex align-items-center justify-content-center surface-ground border-circle p-2 cursor-pointer">
          <i *ngIf="!collapsed" class="pi pi-chevron-up"></i>
          <i *ngIf="collapsed" class="pi pi-chevron-down"></i>
        </span>

      </div>
  
    </div>
  </div>
  

  <div [@collapse]="collapsed" class="collapse-wrap surface-card border-round-bottom" [class.opened]="!collapsed">
    
    
    <div class="no-h-padding border-none border-0 border-top-1 border-100 my-2 -mx-3"></div>

    <ng-container *ngIf="order?.orderItems ?? [] as orderItems">

      <!-- order items -->
      <ng-container *ngFor="let item of orderItems; let i = index">
        <div class="p-3 border-bottom-1 border-100 -mx-3">

          <app-customer-card-order-order-item [orderItem]="item" [order]="order"></app-customer-card-order-order-item>

        </div>

        <!-- DISCOUNTS -->
        <ng-container *ngFor="let discount of item.discounts; let k = index">

          <div class="p-3 border-bottom-1 border-100 -mx-3">

            <app-customer-card-order-order-item-discount
              [discount]="discount"
              [orderItem]="item"
              [order]="order">
            </app-customer-card-order-order-item-discount>

          </div>

        </ng-container>
        <!-- / -->

        @if (item.stornoFee) {
          <div class="p-3 border-bottom-1 border-100 -mx-3 flex justify-content-between">
            <div>
              <h5 class="m-0">
                <!-- {{ item.itemTitle }} -->
                {{ item.itemTitle + ' (' + ('customer-orders.orders.item.storno-fee-label' | translate) + ')' }}
              </h5>
              <!-- <div class="details-block-wrap text-base">
                {{ ('customer-orders.orders.item.storno-fee-label' | translate) }}
              </div> -->
            </div>
            <div class="font-bold flex align-items-center justify-content-end">
              {{ [{ currency: item.currency, value: item.stornoFee }] | price | async }}
            </div>
          </div>
        }

      </ng-container>

      @if (o.summary.orderStornoFee) {
        <div class="p-3 border-bottom-1 border-100 -mx-3 flex justify-content-between">
          <div>
            <h5 class="m-0">
              {{ 'customer-orders.orders.item.storno-fee-label' | translate }}
            </h5>
          </div>
          <div class="font-bold flex align-items-center justify-content-end">
            {{ [o.summary.orderStornoFee] | price | async }}
          </div>
        </div>
      }
    </ng-container>
  
    <!-- ORDER SUMMARY -->
    <app-customer-order-item-wrap  [bgColor]="orderSummaryBgColor" textColorClass="text-white" class="-mx-3 my-2 block">

      <ng-container slot="detail">

        <!-- Total price without VAT -->
        <ng-container *ngTemplateOutlet="summaryRow, context:{
          left: 'customer-orders.orders.item.summary.total-without-vat-label' | translate,
          right: [o.summary.totalPriceWithoutVat] | price | async,
          rightTextLineThrough: o.state === OrderState.CANCELLED
        }"></ng-container>
        <!-- /Total price without VAT -->

        <ng-container *ngIf="!orderHelper.isVatZero(o)">
          <!-- distinct VAT values -->
          <ng-container *ngFor="let vat of o.summary.vatValues">
            <ng-container *ngIf="vat.vat > 0">
              <ng-container *ngTemplateOutlet="summaryRow, context:{
                left: 'customer-orders.orders.item.summary.vat-label' | translate:{vatValue: vat.vat | percent},
                right: vat.price | price | async,
                rightTextLineThrough: o.state === OrderState.CANCELLED
              }"></ng-container>
            </ng-container>
          </ng-container>
          <!-- /distinct VAT values-->

          <!-- Total VAT price -->
          <!-- zobrazit pouze pokud vic distinct dph -->
          <!-- <ng-container *ngIf="o.summary.vatValues.length > 1">
            <ng-container *ngTemplateOutlet="summaryRow, context:{
              left: 'customer-orders.orders.item.summary.total-vat-label' | translate,
              right: [o.summary.totalVatPrice] | price | async
            }"></ng-container>
          </ng-container> -->
          <!-- /Total VAT price -->
        </ng-container>

        <ng-container *ngIf="o.summary.stornoFeePrice.value">
          <ng-container *ngTemplateOutlet="summaryRow, context:{
            left: 'customer-orders.orders.item.summary.storno-label' | translate,
            right: [o.summary.stornoFeePrice] | price | async
          }"></ng-container>
        </ng-container>

        <ng-container *ngTemplateOutlet="summaryRow, context:{
          left: 'customer-orders.orders.item.summary.total-label' | translate,
          right: [o.summary.totalPrice] | price | async,
          rightClass: 'font-bold',
          leftClass: 'font-bold'
        }"></ng-container>

        <!-- divider -->
        <div class="no-h-padding border-none border-0 border-bottom-1 border-dashed w-full"></div>
        <!-- /divider -->

        <!-- Total paid price -->
        <ng-container>
          <ng-container *ngTemplateOutlet="summaryRow, context:{
            left: 'customer-orders.orders.item.summary.already-paid-label' | translate,
            right: [o.summary.totalPaid] | price:o.summary.totalPaid.currency:true | async
          }"></ng-container>
        </ng-container>
        <!-- /Total paid price -->

        <!-- Prepaid credit -->
        @if (o.summary.refundedToCreditPrice?.value) {
          <ng-container *ngTemplateOutlet="summaryRow, context:{
            left: 'customer-orders.orders.item.summary.prepaid-credit-label' | translate,
            right: [o.summary.refundedToCreditPrice!] | price | async
          }"></ng-container>
        }
        <!-- /Prepaid credit -->

        <!-- Refunded to user -->
        @if (o.summary.refundedToUserPrice?.value) {
          <ng-container *ngTemplateOutlet="summaryRow, context:{
            left: 'customer-orders.orders.item.summary.refunded-label' | translate,
            right: [o.summary.refundedToUserPrice!] | price | async
          }"></ng-container>
        }
        <!-- /Refunded to user -->

        <!-- To be paid -->
        <ng-container>
          <div class="no-h-padding border-none border-0 border-bottom-2 border-solid"></div>
          @if ((o.summary.finalPrice.value || 0) >= 0) {
            <ng-container *ngTemplateOutlet="summaryRow, context: {
              left: ('customer-orders.orders.item.summary.to-be-paid-label' | translate),
              right: [o.summary.finalPrice] | price | async
            }"></ng-container>
          } @else {
            <ng-container *ngTemplateOutlet="summaryRow, context: {
              left: ('customer-orders.orders.item.summary.overpayment-label' | translate),
              right: [o.summary.finalPrice] | price:undefined:true | async
            }"></ng-container>
          }
        </ng-container>
        <!-- / -->
      </ng-container>
      
    </app-customer-order-item-wrap>
    <!-- / -->

    <!-- user notes -->
    <div *ngIf="order.userNotes.length">
      <span class="font-bold">
        {{ 'admin.customer-card-order.order.note-label' | translate }}
      </span>

      <p *ngFor="let d of order.userNotes" class="m-0 mt-1">
        {{ d.text }}
      </p>
    </div>

    @if (order.state === OrderState.PENDING || isOrderInvoiceIssuable) {
      <div class="flex mt-3 column-gap-2">
        @if (order.state === OrderState.PENDING) {
          <button
            (click)="onChangeDeadline(o)"
            pButton
            label="{{ 'admin.customer-card-order.order.edit-deadline-btn.label' | translate }}"
            class="p-button-outlined p-button-secondary p-button-sm">
          </button>
        }
        @if (isOrderInvoiceIssuable) {
          <button
            (click)="onIssueInvoice()"
            pButton
            [loading]="fetchingInvoicePlaceholder"
            label="{{ 'admin.customer-card-order.order.issue-invoice-btn.label' | translate }}"
            class="p-button-outlined p-button-secondary p-button-sm">
          </button>
        }
      </div>
    }
  </div>
</div>


<ng-template #summaryRow let-left="left" let-right="right" let-rightClass="rightClass" let-leftClass="leftClass" let-rightTextLineThrough="rightTextLineThrough">

  <div class="flex px-3 justify-content-between w-full">
    <span class="{{ leftClass ? leftClass : '' }}">
      {{ left }}
    </span>

    <span class="{{ rightClass ? rightClass : '' }}" [class.line-through]="rightTextLineThrough">
      {{ right }}
    </span>
  </div>

</ng-template>