import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-tooltip-copy-content',
  standalone: true,
  imports: [
    TranslateModule,
  ],
  templateUrl: './tooltip-copy-content.component.html',
  styleUrl: './tooltip-copy-content.component.scss'
})
export class TooltipCopyContentComponent {

  @ViewChild('tooltipContent') public tooltipContent: TemplateRef<any> | undefined;
  @Input() value: string | undefined;
  @Input() copied: boolean = false;

  get content() {
    return this.tooltipContent;
  }

}
